import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import {
    makeStyles,
    Button,
    TextField,
    Checkbox,
} from '@material-ui/core';
import { Form, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import useImagesActions from 'components/modules/inventory/hooks/useImagesActions';
import ModalUtils from 'utils/ModalUtils';
import ArrayUtils from 'lib/ArrayUtils';
import { FetchPolicy } from 'utils/enum/Core';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import { v1 as uuid } from 'uuid';
import StringUtils from 'lib/StringUtils';
import Email from 'utils/Email';
import ButtonStyles from 'styles/theme/Button';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';
import InputNumber from 'components/widgets/InputNumber';
import MarketCompsTab from 'components/modules/inventory/read/MarketCompsTab';
import PhoneInput from 'components/widgets/form/PhoneInput';
import InspectionImagesDialog from 'components/modules/inventory/read/InspectionImagesDialog';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    main: {
        flex: 1,
        width: '100%',
        height: '100%',
    },
    header: {
        color: theme.palette.text.white,
        fontSize: '20px',
        fontWeight: 'bold',
        padding: '20px',
        backgroundColor: theme.palette.background.bigStone,
    },
    content: {
        display: 'flex',
        padding: '10px',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    leftPanel: {
        width: '300px',
        flexShrink: 0,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    panelTitle: {
        color: theme.palette.background.white,
        backgroundColor: theme.palette.background.bigStone,
        display: 'flex',
        justifyContent: 'center',
        padding: '5px',
        fontSize: '15px',
        marginBottom: '20px',
    },
    field: {
        display: 'flex',
        alignItems: 'center',
        margin: 0,
        flexWrap: 'nowrap',
        marginBottom: '20px',
        '& > label': {
            width: '120px',
            marginTop: '5px',
            fontSize: '14px',
        },
        '& > input': {
            fontSize: '14px',
        },
        '& > button': {
            marginLeft: '75px',
            [theme.breakpoints.down('sm')]: {
                marginLeft: '90px',
            },
        },
        '& > div': {
            width: '100%',
        },
        '& > span': {
            width: '100%',
            display: 'block',
            justifyContent: 'flex-start',
        },
        '& > span:hover': {
            backgroundColor: 'initial !important',
        },
    },
    chart: {
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            overflow: 'initial',
        },
    },
    uploadButton: {
        marginTop: '-17px',
    },
    '@global': {
        '.MuiButton-root.Mui-disabled': {
            color: 'rgba(255,255,255,0.6) !important',
        },
    },
    required: {
        border: `1px solid ${theme.palette.border.red}`,
    },
    checkboxes: {
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'flex-end',
        '& > div': {
            width: '105px',
            '& > span': {
                padding: 0,
                width: '30px',
            },
        },
    },
}));

const INIT_STATE = {
    vin: null,
    tempVin: null,
    odometer: 0,
    year: null,
    make: null,
    model: null,
    trim: null,
    engine: null,
    lotName: null,
    customer: {
        customerId: null,
        firstName: null,
        lastName: null,
        cellPhone: null,
        email: null,
    },
    notes: null,
    purchased: false,
    wholesale: false,
    images: 0,
    appraisalId: null,
    suggestedPrice: 0,
    marketPercentage: 0,
    repairs: 0,
    markUp: 0,
    appraisedValue: 0,
    isPicturesFormOpen: false,
    appraisalImages: null,
    isUploadingImages: false,
    openImagePreview: false,
    photoIndex: -1,
    key: uuid(),
};

const Appraisals = () => {
    const keyStore = new KeyStore();
    const canWrite = keyStore.hasPermission(Permission.INVENTORY_APPRAISALS_WRITE);

    const location = useLocation();
    const {
        moveType, addImages, deleteImages, setPhotoIndex,
    } = useImagesActions();
    const decodedDataRef = useRef();
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState(cloneDeep(INIT_STATE));

    const {
        customer: {
            firstName,
            lastName,
            cellPhone,
            email,
        },
        notes,
        purchased,
        wholesale,
    } = state;

    const [getImages] = useLazyQuery(InventoryQuery.PULL_APPRAISAL_IMAGES, {
        onCompleted: (response) => {
            if (response) {
                const images = response.pullAppraisalImages;
                setState({
                    ...state,
                    appraisalImages: images,
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [pullAppraisal] = useLazyQuery(InventoryQuery.PULL_APPRAISAL_DATA, {
        onCompleted: (response) => {
            if (response) {
                const data = response.pullAppraisalData;
                if (data) {
                    setState({
                        ...state,
                        ...decodedDataRef.current,
                        appraisalId: data.appraisalListId,
                        ...(data.customer ? {
                            customer: {
                                ...state.customer,
                                customerId: data.customer.customerId,
                                firstName: data.customer.firstName,
                                lastName: data.customer.lastName,
                                cellPhone: data.customer.cellPhone,
                                email: data.customer.email,
                            },
                        } : {}),
                        odometer: data.miles,
                        notes: data.notes,
                        purchased: data.purchased,
                        wholesale: data.wholesale,
                        images: data.images || 0,
                        suggestedPrice: data.suggestedPrice,
                        repairs: data.repairs,
                        markUp: data.markUp,
                    });
                }
            }
        },
        onError: () => {
            setState({
                ...INIT_STATE,
                odometer: state.odometer,
                lotName: state.lotName,
                ...decodedDataRef.current,
            });
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [decodeVIN, { loading: decodingVin }] = useLazyQuery(InventoryQuery.DECODE_VIN_BASIC_SPECS, {
        onCompleted: (response) => {
            if (response) {
                const data = response.decodeVinBasicSpecs;
                if (!data.isValid) {
                    ModalUtils.errorMessage(null, 'Error decoding the VIN provided');
                    return;
                }

                decodedDataRef.current = {
                    vin: state.tempVin,
                    tempVin: state.tempVin,
                    year: data.year,
                    make: data.make,
                    model: data.model,
                    trim: !data.trim ? 'ALL' : data.trim[0].toUpperCase(),
                    engine: ArrayUtils.isNotEmpty(data.engines) ? data.engines[0].toUpperCase() : null,
                    key: uuid(),
                };

                pullAppraisal({
                    variables: {
                        vin: state.tempVin,
                    },
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [saveAppraisalData, { loading: savingAppraisalData }] = useMutation(InventoryMutation.SAVE_APPRAISAL_DATA, {
        onCompleted: (response) => {
            if (response) {
                const data = response.saveAppraisalData;
                setState({
                    ...state,
                    appraisalId: data.appraisalListId,
                    ...(data.customer ? {
                        customer: {
                            ...state.customer,
                            customerId: data.customer.customerId,
                            cellPhone: data.customer.cellPhone,
                            email: data.customer.email,
                        },
                    } : {}),
                });

                ModalUtils.successMessage(null, 'Appraisal saved successfully');
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const onDecodeVin = (vin) => {
        if (!state.tempVin && !vin) return;

        decodeVIN({
            variables: {
                vin: (vin || state.tempVin).trim(),
            },
        });
    };

    useEffect(() => {
        const userLot = new KeyStore().getSelectedLot();
        const params = new URLSearchParams(location.search);
        const paramVIN = params.get('vin');
        const paramOdometer = params.get('odometer');

        setState({
            ...state,
            tempVin: paramVIN || state.tempVin,
            odometer: paramOdometer || state.odometer,
            lotName: userLot?.isDefault ? userLot.lotName : null,
        });

        if (paramVIN) onDecodeVin(paramVIN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChange = (name, value, parentProperty) => {
        if (parentProperty) {
            setState({
                ...state,
                [parentProperty]: {
                    ...state[parentProperty],
                    [name]: value,
                },
            });

            return;
        }

        setState({
            ...state,
            [name]: value,
            ...(name === 'wholesale' && value ? {
                customer: {
                    customerId: null,
                    firstName: null,
                    lastName: null,
                    cellPhone: null,
                    email: null,
                },
            } : {}),
        });
    };

    const saveAppraisal = () => {
        if (!wholesale && !StringUtils.isEmpty(email) && !Email.isValidEmail(email)) {
            ModalUtils.errorMessage(null, 'Please check the format of the email');
            return;
        }

        saveAppraisalData({
            variables: {
                input: {
                    appraisalListId: state.appraisalId,
                    customer: !wholesale ? {
                        firstName: firstName.trim(),
                        lastName: lastName.trim(),
                        cellPhone,
                        email: !StringUtils.isEmpty(email) ? email.trim() : null,
                    } : null,
                    vin: state.vin.trim(),
                    year: state.year,
                    make: state.make,
                    model: state.model,
                    trim: state.trim,
                    miles: state.odometer,
                    suggestedPrice: state.suggestedPrice,
                    marketPercentage: state.marketPercentage,
                    repairs: state.repairs,
                    markUp: state.markUp,
                    appraisedValue: state.appraisedValue,
                    notes,
                    purchased,
                    wholesale,
                },
            },
        });
    };

    const setHeaderValues = (
        suggestedPrice,
        marketPercentage,
        repairs,
        markUp,
        appraisedValue,
    ) => {
        setState({
            ...state,
            suggestedPrice,
            marketPercentage: Number(marketPercentage),
            repairs,
            markUp,
            appraisedValue,
        });
    };

    const onAddImages = async (files) => {
        const dispatcherPayload = {
            ...state,
            isUploadingImages: true,
        };

        const additionalDispatcherPayload = {
            ...state,
            isUploadingImages: false,
        };

        addImages(
            files,
            setState,
            dispatcherPayload,
            additionalDispatcherPayload,
            'appraisalListId',
            state.appraisalId,
            null,
            InventoryMutation.UPLOAD_APPRAISAL_IMAGE,
            'uploadAppraisalImage',
            InventoryMutation.SAVE_APPRAISAL_IMAGES,
            getImages,
        );
    };

    const togglePicturesForm = () => {
        if (!state.isPicturesFormOpen) {
            getImages({
                variables: {
                    appraisalListId: state.appraisalId,
                },
            });
        }

        setState({
            ...state,
            isPicturesFormOpen: !state.isPicturesFormOpen,
        });
    };

    const onOpenImage = (index) => {
        setState({
            ...state,
            openImagePreview: !state.openImagePreview,
            photoIndex: index,
        });
    };

    const toggleImagePreview = () => {
        setState({
            ...state,
            openImagePreview: !state.openImagePreview,
        });
    };

    const onSetPhotoIndex = (type) => setPhotoIndex(
        type,
        state.photoIndex,
        state.appraisalImages,
        setState,
        null,
    );

    const onDeleteImages = async (selectedImages, toggleImagesDeletionPrompt) => {
        const payload = {
            variables: {
                appraisalListId: state.appraisalId,
            },
        };

        deleteImages(
            state.appraisalImages,
            'appraisalListImageId',
            InventoryMutation.REMOVE_APPRAISAL_IMAGES,
            selectedImages,
            toggleImagesDeletionPrompt,
            getImages,
            payload,
        );
    };

    const vinStandardLength = 17;
    const vinHasNotStandardLength = (state.tempVin || '').length !== vinStandardLength;
    const contactInfoNotProvided = StringUtils.isEmpty(cellPhone) && StringUtils.isEmpty(email);
    const customerInfoProvided = !StringUtils.isEmpty(firstName) && !StringUtils.isEmpty(lastName) && !contactInfoNotProvided;
    const isSaveButtonDisabled = vinHasNotStandardLength
        || state.odometer <= 0
        || StringUtils.isEmpty(state.year)
        || StringUtils.isEmpty(state.make)
        || StringUtils.isEmpty(state.model)
        || StringUtils.isEmpty(state.trim)
        || (!customerInfoProvided && !wholesale)
        || savingAppraisalData
        || decodingVin;
    return (
        <div className={classes.main}>
            <div className={classes.header}>
                Appraisals
            </div>
            <div className={classes.content}>
                <div className={classes.leftPanel}>
                    <div className={classes.panelTitle}>
                        Vehicle Information
                    </div>
                    <div className={classes.panelContent}>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label>VIN:</Form.Label>
                            <Form.Control
                                className={vinHasNotStandardLength ? classes.required : ''}
                                onChange={({ target: { name, value } }) => onChange(name, value)}
                                maxLength={vinStandardLength}
                                value={state.tempVin || ''}
                                name="tempVin"
                                type="text"
                                size="sm"
                            />
                        </Form.Group>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label>Odometer:</Form.Label>
                            <InputNumber
                                className={state.odometer <= 0 ? classes.required : ''}
                                value={state.odometer}
                                min={0}
                                decimalScale={0}
                                thousandSeparator
                                size="sm"
                                onChange={(value) => onChange('odometer', value)}
                            />
                        </Form.Group>
                        <Form.Group as={Row} className={classes.field}>
                            <Button
                                fullWidth
                                disabled={
                                    !state.tempVin
                                    || state.tempVin.length !== vinStandardLength
                                    || state.odometer <= 0
                                    || decodingVin
                                }
                                className={classes.containedSecondaryInfo}
                                size="medium"
                                onClick={() => onDecodeVin()}
                            >
                                Decode
                            </Button>
                        </Form.Group>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label>Year:</Form.Label>
                            <Form.Control
                                readOnly
                                value={state.year || ''}
                                type="text"
                                size="sm"
                            />
                        </Form.Group>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label>Make:</Form.Label>
                            <Form.Control
                                readOnly
                                value={state.make || ''}
                                type="text"
                                size="sm"
                            />
                        </Form.Group>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label>Model:</Form.Label>
                            <Form.Control
                                readOnly
                                value={state.model || ''}
                                type="text"
                                size="sm"
                            />
                        </Form.Group>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label>Trim:</Form.Label>
                            <Form.Control
                                readOnly
                                value={state.trim || ''}
                                type="text"
                                size="sm"
                            />
                        </Form.Group>
                    </div>
                    <div className={classes.panelTitle}>
                        Customer
                    </div>
                    <div className={classes.panelContent}>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label>First Name:</Form.Label>
                            <Form.Control
                                className={!firstName && !wholesale ? classes.required : ''}
                                onChange={({ target: { name, value } }) => onChange(name, value, 'customer')}
                                maxLength={70}
                                value={firstName || ''}
                                name="firstName"
                                type="text"
                                size="sm"
                            />
                        </Form.Group>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label>Last Name:</Form.Label>
                            <Form.Control
                                className={!lastName && !wholesale ? classes.required : ''}
                                onChange={({ target: { name, value } }) => onChange(name, value, 'customer')}
                                maxLength={70}
                                value={lastName || ''}
                                name="lastName"
                                type="text"
                                size="sm"
                            />
                        </Form.Group>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label>Cell Phone:</Form.Label>
                            <PhoneInput
                                className={contactInfoNotProvided && !wholesale ? classes.required : ''}
                                value={cellPhone || ''}
                                size="sm"
                                onChange={(value) => onChange('cellPhone', value, 'customer')}
                            />
                        </Form.Group>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label>Email:</Form.Label>
                            <Form.Control
                                className={contactInfoNotProvided && !wholesale ? classes.required : ''}
                                onChange={({ target: { name, value } }) => onChange(name, value, 'customer')}
                                maxLength={100}
                                value={email || ''}
                                name="email"
                                type="email"
                                size="sm"
                            />
                        </Form.Group>
                        <Form.Group as={Row} className={classes.field}>
                            <Form.Label>Notes:</Form.Label>
                            <TextField
                                multiline
                                fullWidth
                                rows={3}
                                variant="outlined"
                                value={notes || ''}
                                name="notes"
                                onChange={({ target: { name, value } }) => onChange(name, value)}
                            />
                        </Form.Group>
                        <div className={classes.checkboxes}>
                            <Form.Group as={Row} className={classes.field}>
                                <Form.Label>Purchased:</Form.Label>
                                <Checkbox
                                    color="default"
                                    disableRipple
                                    checked={purchased || false}
                                    onChange={(_, value) => onChange('purchased', value)}
                                />
                            </Form.Group>
                            <Form.Group as={Row} className={classes.field}>
                                <Form.Label>Wholesale:</Form.Label>
                                <Checkbox
                                    color="default"
                                    disableRipple
                                    checked={wholesale || false}
                                    onChange={(_, value) => onChange('wholesale', value)}
                                />
                            </Form.Group>
                        </div>
                        {canWrite && (
                            <Form.Group as={Row} className={classes.field}>
                                <Button
                                    fullWidth
                                    disabled={isSaveButtonDisabled}
                                    className={classes.containedSuccess}
                                    size="medium"
                                    onClick={saveAppraisal}
                                >
                                    Save
                                </Button>
                            </Form.Group>
                        )}
                        {canWrite && (
                            <Form.Group as={Row} className={classes.field}>
                                <Button
                                    fullWidth
                                    disabled={!state.appraisalId}
                                    className={clsx(classes.containedSecondaryInfo, classes.uploadButton)}
                                    size="medium"
                                    onClick={togglePicturesForm}
                                >
                                    {`Upload Photos (${state.appraisalImages?.length || state.images})`}
                                </Button>
                            </Form.Group>
                        )}
                    </div>
                </div>
                <div className={classes.chart}>
                    {state.lotName && (
                        <MarketCompsTab
                            key={state.key}
                            record={{
                                detail: {
                                    stockNumber: -1,
                                    miles: state.odometer,
                                    year: state.year,
                                    make: state.make,
                                    model: state.model,
                                    trim: state.trim,
                                    engine: state.engine,
                                    lotName: state.lotName,
                                    vin: state.vin,
                                },
                            }}
                            canWrite={false}
                            isAppraisals
                            headerValues={{
                                suggestedPrice: state.suggestedPrice,
                                repairs: state.repairs,
                                markUp: state.markUp,
                            }}
                            setHeaderValues={setHeaderValues}
                        />
                    )}
                </div>
            </div>
            <InspectionImagesDialog
                isPicturesFormOpen={state.isPicturesFormOpen}
                isUploadingImages={state.isUploadingImages}
                openImagePreview={state.openImagePreview}
                addImages={onAddImages}
                canWrite={canWrite}
                togglePicturesForm={togglePicturesForm}
                selectedItemImages={state.appraisalImages}
                onOpenImage={onOpenImage}
                photoIndex={state.photoIndex}
                toggleImagePreview={toggleImagePreview}
                setPhotoIndex={onSetPhotoIndex}
                onDeleteImages={onDeleteImages}
                moveType={moveType}
                isApproved={false}
            />
        </div>
    );
};

export default Appraisals;
