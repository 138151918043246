/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuid } from 'uuid';
import { makeStyles } from '@material-ui/core';
import StringUtils from 'lib/StringUtils';
import BIHelper from 'utils/BusinessIntelligenceHelper';
import PivotTableDetails from 'components/widgets/businessIntelligence/PivotTableDetails';
import PivotTableInner from 'components/widgets/businessIntelligence/PivotTableInner';

const useStyles = makeStyles((theme) => ({
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: '13px',
        '& > span': {
            color: theme.palette.text.white,
            backgroundColor: theme.palette.secondary.main,
            padding: '10px',
        },
    },
}));

const PivotTableWrapper = React.memo(({
    parentWidth,
    input = [],
    rawData = [],
    formattingData = [],
    pivotDefaultColumns = [],
}) => {
    const classes = useStyles();
    const [state, setState] = useState({
        parentWidth: 0,
        parentId: uuid(),
        mainData: [],
        tableColumns: [],
        upperColumns: [],
        isDetailsOpen: false,
        details: [],
        detailsFilters: [],
    });

    const {
        tableColumns,
        upperColumns,
        mainData,
        isDetailsOpen,
        details,
        detailsFilters,
    } = state;

    useEffect(() => {
        if (parentWidth > 0) setState((previousState) => ({ ...previousState, parentWidth }));
    }, [parentWidth]);

    const getUpperColumns = (columns) => {
        const result = [];
        const firstRecord = columns.find((c) => c.columnPathValues != null);
        if (firstRecord) {
            const levels = firstRecord.columnPathValues.split('|').length;
            for (let x = 0; x < levels; x += 1) {
                const columnsInLevel = [];

                columns.forEach(({
                    columnPathValues,
                }) => {
                    if (!columnPathValues) columnsInLevel.push('');
                    if (columnPathValues) columnsInLevel.push(columnPathValues.split('|')[x]?.trim());
                });

                result.push(columnsInLevel);
            }
        }

        return result;
    };

    useEffect(() => {
        if (input.length === 0) return;
        const columnMinimumWidth = 150;
        const { columns } = input[0];

        const style = window.getComputedStyle(document.body);
        const fontFamily = style.getPropertyValue('font-family');

        if (tableColumns.length > 0) {
            const newTableColumns = [];

            columns.forEach(({ name, columnPathValues }) => {
                const current = tableColumns.find((item) => item.name === (columnPathValues ?? name));
                if (!current) {
                    const calculatedWidth = Math.ceil(BIHelper.calculateTextWidthOnScreen(name, `bold 12px ${fontFamily}`) || 0);
                    newTableColumns.push({
                        name,
                        width: (calculatedWidth > columnMinimumWidth ? calculatedWidth : columnMinimumWidth) + 25,
                    });
                } else {
                    newTableColumns.push(current);
                }
            });

            setState((previousState) => ({
                ...previousState,
                mainData: input,
                tableColumns: newTableColumns,
                upperColumns: getUpperColumns(columns),
                parentId: uuid(),
            }));
            return;
        }

        const cols = columns.map((c) => {
            const { name } = c;
            const calculatedWidth = Math.ceil(BIHelper.calculateTextWidthOnScreen(c.name, `bold 12px ${fontFamily}`) || 0);

            return {
                name,
                width: (calculatedWidth > columnMinimumWidth ? calculatedWidth : columnMinimumWidth) + 25,
            };
        });

        setState((previousState) => ({
            ...previousState,
            mainData: input,
            tableColumns: cols,
            upperColumns: getUpperColumns(columns),
            parentId: uuid(),
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input]);

    const filterDetails = (filters, data) => data
        .filter((record) => filters
            .every((i) => record
                .find((r) => r.name === i.name
                    && (
                        r.value === i.value
                        || (
                            i.value === '(Blanks)'
                            && (
                                r.value == null
                                || StringUtils.isEmpty(r.value)
                                || r.value === 'null'
                            )
                        )
                    ))));

    const openDetails = (currentPath, columnPath, columnPathValues) => {
        if (columnPath && columnPathValues) {
            const cols = columnPath.split('|').map((c) => c.trim());
            const vals = columnPathValues.split('|').map((c) => c.trim());
            const filters = [
                ...currentPath,
                ...cols.map((c, index) => ({ name: c, value: vals[index] })),
            ];

            setState((previousState) => ({
                ...previousState,
                isDetailsOpen: true,
                details: filterDetails(filters, rawData),
                detailsFilters: filters,
            }));

            return;
        }

        setState((previousState) => ({
            ...previousState,
            isDetailsOpen: true,
            details: filterDetails(currentPath, rawData),
            detailsFilters: currentPath,
        }));
    };

    if (input.length === 0) return (<div />);
    if (mainData.length === 0) {
        return (
            <div className={classes.loading}>
                <span>Rendering table</span>
            </div>
        );
    }

    return (
        <>
            <PivotTableInner
                parentId={state.parentId}
                parentWidth={state.parentWidth}
                tableColumns={tableColumns}
                upperColumns={upperColumns}
                mainData={mainData}
                formattingData={formattingData}
                pivotDefaultColumns={pivotDefaultColumns}
                openDetails={openDetails}
            />
            {isDetailsOpen && (
                <PivotTableDetails
                    data={details}
                    filters={detailsFilters}
                    toggleDialog={() => setState((previousState) => ({ ...previousState, isDetailsOpen: false }))}
                />
            )}
        </>
    );
}, (prevProps, nextProps) => prevProps.key === nextProps.key);

PivotTableWrapper.defaultProps = {
    input: [],
    rawData: [],
    formattingData: [],
    parentWidth: -1,
    pivotDefaultColumns: [],
};

PivotTableWrapper.propTypes = {
    input: PropTypes.array,
    rawData: PropTypes.array,
    formattingData: PropTypes.array,
    parentWidth: PropTypes.number,
    pivotDefaultColumns: PropTypes.array,
};

export default PivotTableWrapper;
