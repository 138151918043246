import React, {
    useEffect, useCallback, useReducer,
} from 'react';

import clsx from 'clsx';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import Select from 'components/widgets/Select';
import { Form, Col, Row } from 'react-bootstrap';
import { useMutation, useQuery } from '@apollo/client';
import LotQuery from 'services/graphQL/query/LotQuery';
import { PDFDocument, PDFTextField, PDFCheckBox } from 'pdf-lib';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import DialogActions from 'components/widgets/modal/DialogActions';
import { makeStyles, DialogContent, Dialog } from '@material-ui/core';
import { FormMapperSchema } from 'utils/schema/settings/FormMapperSchema';
import FormsMapperQuery from 'services/graphQL/query/setting/FormsMapperQuery';
import FormsMapperMutation from 'services/graphQL/mutate/setting/FormsMapperMutation';

const initialState = {
    file: null,
    states: [],
    formName: '',
    formState: '',
    fieldData: [],
    databaseFields: [],
};

const ACTION_TYPE = {
    ON_CHANGE: 'onChange',
    INIT_SAVED_DATA: 'initSavedData',
    ON_CHANGE_FIELD_DATA: 'onChangeFieldData',
};

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        padding: theme.spacing(3, 1.5),
    },
    containerFields: {
        width: 250,
        minWidth: 250,
        display: 'flex',
        overflow: 'hidden',
        borderRadius: '4px',
        flexDirection: 'column',
        border: '1px solid #d9d9d9',
        '& label': {
            fontWeight: 500,
            margin: '8px 0',
            display: 'block',
            textAlign: 'center',
        },
    },
    listFields: {
        overflow: 'auto',
        '& li': {
            fontSize: '14px',
            cursor: 'pointer',
            padding: theme.spacing(1, 2),
            borderTop: '1px solid #e8e8e8',
        },
    },
    body: {
        display: 'flex',
        padding: theme.spacing(0.5),
        minHeight: '-webkit-fill-available',
    },
    formGroup: {
        overflow: 'auto',
        marginBottom: 0,
    },
    paperScrollPaper: {
        height: 'calc(100% - 64px)',
    },
    textField: {
        // marginRight: theme.spacing(2),
    },
    select: {
        minWidth: '-webkit-fill-available',
        // marginRight: theme.spacing(2),
    },
}));

const reducer = (state, action) => {
    const {
        type,
        field,
        index,
        value,
    } = action;
    switch (type) {
    case ACTION_TYPE.ON_CHANGE: {
        return update(state, {
            [field]: { $set: value },
        });
    }
    case ACTION_TYPE.INIT_SAVED_DATA: {
        return update(state, {
            file: { $set: null },
            formName: { $set: value.formName || '' },
            formState: { $set: value.formState || '' },
            fieldData: { $set: value.fieldData || [] },
        });
    }
    case ACTION_TYPE.ON_CHANGE_FIELD_DATA: {
        return update(state, {
            fieldData: {
                [index]: {
                    value: { $set: value },
                },
            },
        });
    }
    default:
        return state;
    }
};

const FormMapperDialog = ({
    onClose, formId,
}) => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        file, formName, formState, databaseFields, fieldData, states,
    } = state;

    const [upsertFormMapping, { loading: saving }] = useMutation(FormsMapperMutation.SAVE_FORM_MAPPING, {
        onCompleted: (mutationData) => {
            if (mutationData && mutationData.upsertFormMapping) {
                ModalUtils.successMessage(null, 'Saved Successfully');
                onClose({
                    saving: true,
                });
            }
        },
        onError: (mutationError) => {
            ModalUtils.errorMessage(null, mutationError);
        },
    });

    const {
        data, error, loading,
    } = useQuery(FormsMapperQuery.GET_FULL_FORM_FIELDS_LIST, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (error) {
            ModalUtils.errorMessage(error?.graphQLErrors);
            return;
        }

        if (!loading && !isEmpty(data?.getFullFormFieldsList)) {
            const finalList = [...data.getFullFormFieldsList];
            if (finalList.length > 0) finalList.unshift({ name: '', columnName: '' });
            dispatch({
                field: 'databaseFields',
                type: ACTION_TYPE.ON_CHANGE,
                value: finalList.map((item) => ({ label: item.name, value: item.columnName })),
            });
        }
    }, [data, loading, error]);

    const {
        data: lotsData,
        loading: loadingLots,
        error: errorLoadingLots,
    } = useQuery(LotQuery.GET_LOTS_STATE, {
        variables: {
            filter: {
                active: true,
            },
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (errorLoadingLots) {
            ModalUtils.errorMessage(errorLoadingLots?.graphQLErrors);
            return;
        }

        if (!loadingLots && !isEmpty(lotsData)) {
            dispatch({
                field: 'states',
                type: ACTION_TYPE.ON_CHANGE,
                value: Array.from(new Set(lotsData.lotList.map((item) => item.lotState))).map((item) => ({ label: item, value: item })),
            });
        }
    }, [lotsData, loadingLots, errorLoadingLots]);

    const {
        data: savedData, error: savedDataError, loading: loadingSavedData,
    } = useQuery(FormsMapperQuery.GET_FORM_BY_ID, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        variables: { formId },
        skip: formId === 0,
    });

    const getArray = (buffer) => {
        const arrayBuffer = new ArrayBuffer(buffer.length);
        const view = new Uint8Array(arrayBuffer);
        buffer.forEach((_, index) => {
            view[index] = buffer[index];
        });
        return arrayBuffer;
    };

    useEffect(() => {
        if (savedDataError) {
            ModalUtils.errorMessage(savedDataError?.graphQLErrors);
            return;
        }

        if (!loadingSavedData && !isEmpty(savedData)) {
            const { getFormById: formData } = savedData;
            const result = [];

            if (isEmpty(formData.data)) {
                PDFDocument.load(getArray(formData.compiled.data), { ignoreEncryption: true }).then((pdfDoc) => {
                    pdfDoc.getForm().getFields().forEach((field) => {
                        if (field instanceof PDFTextField || field instanceof PDFCheckBox) {
                            result.push({
                                formField: field.getName(),
                            });
                        }
                    });
                    dispatch({
                        type: ACTION_TYPE.INIT_SAVED_DATA,
                        value: {
                            formState: formData.state,
                            formName: formData.commonName,
                            fieldData: result,
                        },
                    });
                });
            } else {
                dispatch({
                    type: ACTION_TYPE.INIT_SAVED_DATA,
                    value: {
                        formState: formData.state,
                        formName: formData.commonName,
                        fieldData: JSON.parse(formData.data)?.mapField,
                    },
                });
            }
        }
    }, [savedData, loadingSavedData, savedDataError]);

    const onChangeValue = (value, index) => {
        dispatch({
            index,
            value,
            type: ACTION_TYPE.ON_CHANGE_FIELD_DATA,
        });
    };

    const extractFieldData = useCallback(async () => {
        try {
            // Fetch the PDF document
            const pdfBytes = await file.arrayBuffer();

            // Load the PDF document
            const pdfDoc = await PDFDocument.load(pdfBytes, { ignoreEncryption: true });

            const result = [];

            pdfDoc.getForm().getFields().forEach((field) => {
                if (field instanceof PDFTextField || field instanceof PDFCheckBox) {
                    result.push({
                        formField: field.getName(),
                        value: fieldData.find((item) => item.formField === field.getName())?.value,
                    });
                }
            });

            dispatch({
                value: result,
                field: 'fieldData',
                type: ACTION_TYPE.ON_CHANGE,
            });
        } catch (e) {
            ModalUtils.errorMessage(null, e);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file]);

    useEffect(() => {
        if (file) extractFieldData();
    }, [extractFieldData, file]);

    const handleChange = ({ field, value }) => {
        dispatch({
            field,
            value,
            type: ACTION_TYPE.ON_CHANGE,
        });
    };

    const isValidData = isValidSchema(FormMapperSchema, state);
    const { isValid, errors } = isValidData;

    return (
        <Dialog
            open
            fullWidth
            maxWidth="lg"
            classes={{ paperScrollPaper: classes.paperScrollPaper }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogAppBar
                iconSize="sm"
                onClose={onClose}
                title={formId === 0 ? 'Add new form' : 'Update form mapping'}
            />
            <DialogContent className={classes.dialogContent}>
                <div className={classes.body}>
                    <Form.Group as={Col} className={classes.formGroup}>
                        <Form.Group as={Row}>
                            <Form.Label>Form Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={formName}
                                placeholder="Enter the name of the form"
                                onChange={(event) => handleChange({ field: 'formName', value: event.target.value })}
                                className={isValidField(errors, 'formName') ? clsx('invalid-field', classes.textField) : classes.textField}
                            />
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label>Form State</Form.Label>
                            <Select
                                name="formState"
                                options={states}
                                value={formState}
                                className={classes.select}
                                onChange={(field, value) => handleChange({ field, value })}
                            />
                        </Form.Group>
                        <Form.Group as={Row}>
                            <input
                                type="file"
                                accept="pdf/*"
                                onChange={(event) => handleChange({ field: 'file', value: event.target.files[0] })}
                            />
                        </Form.Group>
                        {state.fieldData.map((field, index) => (
                            <Form.Group key={index} as={Row}>
                                <Form.Label>
                                    {`Field name in the form: ${field.formField}`}
                                </Form.Label>
                                <Select
                                    name="result"
                                    value={field.value}
                                    maxMenuHeight={100}
                                    className={classes.select}
                                    options={databaseFields}
                                    onChange={(_, value) => onChangeValue(value, index)}
                                />
                            </Form.Group>
                        ))}
                    </Form.Group>
                </div>

            </DialogContent>
            <DialogActions
                onClickSecondary={onClose}
                disableSecondaryButton={saving}
                disablePrimaryButton={!isValid || saving}
                onClickPrimary={() => upsertFormMapping({
                    variables: {
                        input: {
                            file,
                            formId,
                            formState,
                            commonName: formName,
                            data: { mapField: fieldData.map((item) => ({ formField: item.formField, value: item.value || '' })) },
                        },
                    },
                })}
            />
        </Dialog>
    );
};

FormMapperDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    formId: PropTypes.number.isRequired,
};

export default FormMapperDialog;
