/* eslint-disable no-nested-ternary */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import ModalUtils from 'utils/ModalUtils';
import { FetchPolicy } from 'utils/enum/Core';
import UserContext from 'components/context/UserContext';
import Gallery from 'components/widgets/inventory/Gallery';
import VehicleLocation from 'components/widgets/inventory/VehicleLocation';
import CustomTags from 'components/widgets/CustomTags';
import VehicleNote from 'components/widgets/inventory/VehicleNote';
import TopDetailPanel from 'components/modules/inventory/read/panels/TopDetailPanel';
import InventoryHelper from 'utils/InventoryHelper';
import { Tabs } from 'utils/enum/InventoryEnum';
import { modules } from 'utils/enum/modules';
import ButtonStyles from 'styles/theme/Button';
import { CarfaxIcon, AutocheckIcon, MonroneyLabelIcon } from 'components/icons/index';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';

// Material UI
import {
    makeStyles,
    Grid,
    Paper,
    useMediaQuery,
    useTheme,
    Button,
} from '@material-ui/core';

import InventoryDetailStyle from 'styles/modules/inventory/read/InventoryDetailStyle';
import { useLocation } from 'react-router';
import clsx from 'clsx';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => InventoryDetailStyle.leftPanel(theme));

const DetailLeftPanel = ({
    record,
    stockNumber,
    onChangeVehicleLocation,
    onChangeVehicleNotes,
    invUserTabName,
    onReload,
    blockingStatus,
    currentUserId,
    changeBlockingStatus,
}) => {
    const userData = useContext(UserContext);
    const { userInformation } = userData;
    const { company } = userInformation || {};
    const [stickerURL, setStickerURL] = useState();
    const [autocheckURL, setAutocheckURL] = useState();
    const [carfaxURL, setCarfaxURL] = useState();

    const classes = { ...useStyles(), ...buttonStyles() };
    const theme = useTheme();
    const location = useLocation();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {
        detail = {}, images = [], userTabURL1 = '', userTabURL2 = '',
    } = record || {};
    const selectedTab = InventoryHelper.getSelectedTab(location.hash);

    const carfaxValidationError = () => {
        if (!company?.carfaxId) {
            ModalUtils.errorMessage(null, 'Can\'t open CarFax. Please contact Customer Support');
        }
    };

    const [getCarfax, { loading: carfaxLoading }] = useLazyQuery(InventoryQuery.GET_CARFAX_URL, {
        variables: {
            vin: detail.vin,
        },
        onCompleted: (response) => {
            if (response) {
                const url = response.getCarfaxURL;

                setCarfaxURL(url);
                InventoryHelper.openURLInNewTab(url);
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [getSticker, { loading: stickerLoading }] = useLazyQuery(InventoryQuery.GET_MONRONEY_WINDOW_STICKER, {
        variables: {
            vin: detail.vin,
        },
        onCompleted: (response) => {
            if (response) {
                const data = response.getMonroneyWindowSticker;
                const url = data.jpg;

                setStickerURL(url);
                InventoryHelper.openURLInNewTab(url);
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [getAutocheckContent, { loading: autocheckLoading }] = useLazyQuery(InventoryQuery.GET_AUTOCHECK_REPORT, {
        variables: {
            vin: detail.vin,
        },
        onCompleted: (response) => {
            if (response) {
                const content = Buffer.from(response.getAutocheckReport, 'base64').toString('utf8');
                // eslint-disable-next-line no-undef
                const url = URL.createObjectURL(new Blob([content], { type: 'text/html' }));

                setAutocheckURL(url);
                InventoryHelper.openURLInNewTab(url);
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    return (
        <Paper
            className={clsx(classes.leftPanel, (isMobile && selectedTab !== Tabs.DETAIL) && classes.hideDetails)}
            square
        >
            <div className={classes.basicInfo}>
                <Grid container direction="column" alignItems="center">
                    <Grid item className={classes.imageContain}>
                        <Gallery
                            images={images}
                            thumbnail={record.thumbnail}
                            alternativeThumbnail={record.alternativeThumbnail}
                            bodyStyle={detail.style}
                        />
                    </Grid>
                </Grid>
                <TopDetailPanel
                    stockNumber={stockNumber}
                    invUserTabName={invUserTabName}
                    onReload={onReload}
                    detail={{
                        userTabURL1,
                        userTabURL2,
                        ...detail,
                    }}
                    isExtended
                    blockingStatus={blockingStatus}
                    currentUserId={currentUserId}
                    changeBlockingStatus={changeBlockingStatus}
                />
                <div>
                    <VehicleLocation
                        stockNumber={stockNumber}
                        vehicleLocation={detail.vehicleLocation}
                        onChange={onChangeVehicleLocation}
                    />
                    <CustomTags
                        module={modules.INVENTORY}
                        id={stockNumber}
                    />
                    <VehicleNote
                        stockNumber={stockNumber}
                        notes={detail.notes}
                        onChange={onChangeVehicleNotes}
                    />
                    <div className={classes.externalPages}>
                        <div>
                            <Button
                                disabled={stickerLoading || autocheckLoading || carfaxLoading}
                                size="small"
                                onClick={() => (
                                    !company?.carfaxId ? carfaxValidationError() : carfaxURL ? InventoryHelper.openURLInNewTab(carfaxURL) : getCarfax()
                                )}
                            >
                                <img
                                    alt="Carfax"
                                    src={CarfaxIcon}
                                />
                            </Button>
                            <Button
                                disabled={stickerLoading || autocheckLoading || carfaxLoading}
                                size="small"
                                onClick={() => (autocheckURL ? InventoryHelper.openURLInNewTab(autocheckURL) : getAutocheckContent())}
                            >
                                <img
                                    alt="Autocheck"
                                    src={AutocheckIcon}
                                />
                            </Button>
                        </div>
                        <Button
                            disabled={stickerLoading || autocheckLoading || carfaxLoading}
                            size="small"
                            onClick={() => (stickerURL ? InventoryHelper.openURLInNewTab(stickerURL) : getSticker())}
                        >
                            Original Window Sticker
                            <img
                                alt="Monroney Label"
                                src={MonroneyLabelIcon}
                            />
                        </Button>
                    </div>
                </div>
            </div>
        </Paper>
    );
};

DetailLeftPanel.defaultProps = {
    invUserTabName: {},
    blockingStatus: null,
    currentUserId: 0,
    changeBlockingStatus: () => null,
};

DetailLeftPanel.propTypes = {
    stockNumber: PropTypes.number.isRequired,
    record: PropTypes.object.isRequired,
    onChangeVehicleLocation: PropTypes.func.isRequired,
    onChangeVehicleNotes: PropTypes.func.isRequired,
    onReload: PropTypes.func.isRequired,
    invUserTabName: PropTypes.object,
    blockingStatus: PropTypes.object,
    currentUserId: PropTypes.number,
    changeBlockingStatus: PropTypes.func,
};

export default DetailLeftPanel;
