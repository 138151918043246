import { gql } from '@apollo/client';

export default class CreditMutation {
    static GENERATE_CREDIT_SCORE = gql`    
        mutation generateCreditScore($multipleBureaus: String!, $recordId: Int!, $recordType: String!, $type: String!, $lotId: Int) {
            generateCreditScore(multipleBureaus: $multipleBureaus, recordId: $recordId, recordType: $recordType, type: $type, lotId: $lotId) {
                url
                vendor
            }
        }
    `;
}
