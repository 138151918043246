import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import TodayIcon from '@material-ui/icons/Today';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import { makeStyles, Divider } from '@material-ui/core';
import CreatedOnWithAvatar from 'components/modules/crm/activities/CreatedOnWithAvatar';
import { EventColors } from 'utils/enum/ActivitiesEnum';

const useStyles = makeStyles((theme) => ({
    container: {
        cursor: 'pointer',
        backgroundColor: theme.palette.background.white,
        margin: theme.spacing(0, 0.5),
        marginBottom: theme.spacing(2),
        border: '1px solid #f1f1f1',
        borderRadius: theme.spacing(0.5),
        fontSize: '14px',
        color: theme.palette.text.boulderGray,
        fontWeight: '500',
        '&:last-child': {
            marginBottom: 0,
        },
    },
    subject: {
        marginLeft: theme.spacing(1),
    },
    header: {
        display: 'flex',
        padding: theme.spacing(1.2),
        justifyContent: 'space-between',
    },
    subHeader: {
        padding: theme.spacing(1, 2),
    },
    footer: {
        padding: theme.spacing(3, 2),
    },
    scheduled: {
        color: EventColors.SCHEDULED.backgroundColor,
    },
    confirmed: {
        color: EventColors.CONFIRMED.color,
    },
    show: {
        color: EventColors.SHOW.color,
    },
    noshow: {
        color: EventColors.NOSHOW.color,
    },
    cancelled: {
        color: EventColors.CANCELLED.color,
    },
}));

const getHeader = (record, classes) => {
    const { event: { status } } = record;
    const header = `Appointment: ${status}`;

    return (
        <span className={classes.subject}>{header}</span>
    );
};

const EventActivityItem = ({ record, onOpen }) => {
    const classes = useStyles();
    const { event } = record;
    const header = getHeader(record, classes);
    const date = `${DateUtils.format(event.start, DateFormat.LONG_DATE_TIME_AM_PM)} - ${DateUtils.format(event.end, DateFormat.TIME_AMPM)}`;

    return (
        <div className={clsx(classes.container, 'box-shadow')} onDoubleClick={() => onOpen(record, true)}>
            <div className={classes.header}>
                <div className="d-flex-center">
                    <TodayIcon fontSize="small" className={classes[event.status?.replace(' ', '').toLowerCase()]} />
                    {header}
                </div>
                <CreatedOnWithAvatar
                    record={record}
                />
            </div>
            <Divider />
            <div className={classes.subHeader}>
                <span>Date: </span>
                <span>{date}</span>
            </div>
            <Divider />
            <div className={clsx(classes.footer, 'text-pre-line')}>
                {record.note}
            </div>
        </div>
    );
};

EventActivityItem.propTypes = {
    onOpen: PropTypes.func,
    record: PropTypes.object.isRequired,
};

EventActivityItem.defaultProps = {
    onOpen: () => {},
};

export default EventActivityItem;
