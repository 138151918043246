import { gql } from '@apollo/client';

export default class FormsMapperQuery {
    static GET_FORMS_MAPPED_FIELDS = gql`
        query getFormsMappedFields($search: String) {
            getFormsMappedFields(search: $search) {
                name
                dataType
                columnName
                fieldsTableMappingId
            }
        }
    `;

    static GET_FORM_VIEW_FIELDS_AND_TYPES = gql`
        query getFormViewFieldsAndTypes {
            getFormViewFieldsAndTypes {
                name
                type
            }
        }
    `;

    static GET_FORMS_LIST = gql`
        query getFormsList($search: String) {
            getFormsList(search: $search) {
                id
                state
                category
                commonName
            }
        }
    `;

    static GET_FULL_FORM_FIELDS_LIST = gql`
        query getFullFormFieldsList {
            getFullFormFieldsList {
                name
                columnName
            }
        }
    `;

    static GET_FORM_BY_ID = gql`
        query getFormById($formId: Int!) {
            getFormById(formId: $formId) {
                data
                state
                compiled
                commonName
            }
        }
    `;
}
