import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ButtonStyles from 'styles/theme/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, makeStyles, Tooltip } from '@material-ui/core';
import { Form } from 'react-bootstrap';

// Style
import QueriesBaseLayoutStyles from 'styles/modules/queries/QueriesBaseLayoutStyles';

const useStyles = makeStyles((theme) => QueriesBaseLayoutStyles.childList(theme));
const useSpecificStyles = makeStyles((theme) => QueriesBaseLayoutStyles.sqlEditor(theme));
const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));

const SQLEditor = ({
    query,
    actionAdd,
    queryNameInput,
    actionSave,
    actionRemove,
    actionPreview,
    actionChangeEditorContent,
    isQueryNameValid,
    isQueryTextValid,
}) => {
    const classes = { ...useStyles(), ...useSpecificStyles(), ...buttonStyles() };
    const querySelected = (query.savedQuerieId > 0);
    const isQueryLocked = querySelected && query.locked;

    return (
        <div className={classes.container}>
            <div className={classes.label}>
                Query
            </div>
            <div className={classes.topSplitter}>
                <Form.Group className={classes.queryName}>
                    <Form.Control
                        ref={queryNameInput}
                        className={!isQueryNameValid ? classes.invalidInput : ''}
                        name="queryName"
                        as="input"
                        size="sm"
                        placeholder="Query's Name"
                        value={query.queryName}
                        onChange={actionChangeEditorContent}
                    />
                </Form.Group>
                <Tooltip title={isQueryLocked ? 'This query is locked. Go to settings to unlock' : ''}>
                    <span>
                        <IconButton
                            disabled={!querySelected || isQueryLocked}
                            className={querySelected && !isQueryLocked ? classes.actionRemove : clsx(classes.actionRemove, classes.actionDisabled)}
                            onClick={actionRemove}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            </div>
            <div className={!isQueryTextValid ? clsx(classes.editorContainer, classes.invalidInput) : classes.editorContainer}>
                <Form.Group>
                    <Form.Control
                        as="textarea"
                        size="lg"
                        name="queryText"
                        value={query.queryText}
                        placeholder="Start typing your SQL statement"
                        onChange={actionChangeEditorContent}
                    />
                </Form.Group>
            </div>
            <div className={classes.actions}>
                <div>
                    <Button
                        className={clsx(classes.containedInfo, classes.actionButton)}
                        size="small"
                        onClick={actionAdd}
                    >
                        New
                    </Button>
                    <Button
                        disabled={query.queryText === ''}
                        className={clsx(classes.containedInfo, classes.actionButton)}
                        size="small"
                        onClick={() => actionPreview()}
                    >
                        Run Report
                    </Button>
                </div>
                <Tooltip title={isQueryLocked ? 'This query is locked. Go to settings to unlock' : ''}>
                    <span>
                        <Button
                            disabled={isQueryLocked}
                            className={clsx(classes.containedInfo, classes.actionButton)}
                            size="small"
                            onClick={actionSave}
                        >
                            Save
                        </Button>
                    </span>
                </Tooltip>
            </div>
        </div>
    );
};

SQLEditor.defaultProps = {
    query: {},
    isQueryNameValid: true,
    isQueryTextValid: true,
};

SQLEditor.propTypes = {
    query: PropTypes.object,
    actionAdd: PropTypes.func.isRequired,
    actionSave: PropTypes.func.isRequired,
    actionRemove: PropTypes.func.isRequired,
    actionPreview: PropTypes.func.isRequired,
    actionChangeEditorContent: PropTypes.func.isRequired,
    queryNameInput: PropTypes.object.isRequired,
    isQueryNameValid: PropTypes.bool,
    isQueryTextValid: PropTypes.bool,
};

export default SQLEditor;
