import React, {
    useState, useContext, useEffect, useReducer,
} from 'react';
import LoginStyles from 'styles/modules/LoginStyles';
import { Form, Button } from 'react-bootstrap';
import update from 'immutability-helper';
import KeyStore from 'utils/KeyStore';
import { Redirect } from 'react-router-dom';

// Material UI
import { makeStyles, CircularProgress } from '@material-ui/core';

// Http
import axios from 'axios';
import { isEmpty } from 'lodash';
import ModalUtils from 'utils/ModalUtils';
import { makeUseAxios } from 'axios-hooks';
import UserContext from 'components/context/UserContext';
import MainLayout from 'components/modules/login/MainLayout';
import ForgotPasswordForm from 'components/modules/login/ForgotPasswordForm';
import TwoFactorAuthenticationForm from 'components/modules/login/TwoFactorAuthenticationForm';

const useStyles = makeStyles((theme) => ({
    itemForgot: {
        display: 'block',
        marginTop: '15px',
        textAlign: 'end',
        '& span': {
            fontSize: '0.875rem',
            fontWeight: '400',
            lineHeight: '1.43',
            letterSpacing: '0.01071em',
            color: theme.palette.info.main,
            cursor: 'pointer',
        },
    },
    ...LoginStyles.login(theme),
}));
const keyStore = new KeyStore();
const useAxios = makeUseAxios({
    axios: axios.create({ baseURL: process.env.REACT_APP_GRAPHQL_HTTP }),
});
const FORM_NAME = {
    LOGIN: 'login',
    FORGOT_PASSWORD: 'forgotPassword',
    TWO_FACTOR_AUTHENTICATION: 'twoFactorAuthentication',
};

const ACTION_TYPE = {
    SET_VALUE: 'setValue',
    SET_FORM_NAME: 'setFormName',
    CLOSE_TWO_FACTOR: 'closeTwoFactor',
};

const initialState = {
    userId: null,
    email: null,
    formName: FORM_NAME.LOGIN,
};
const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPE.SET_VALUE:
        return update(state, {
            formName: { $set: FORM_NAME.TWO_FACTOR_AUTHENTICATION },
            email: { $set: action.value.email },
            userId: { $set: action.value.userId },
            cellPhone: { $set: action.value.cellPhone },
        });
    case ACTION_TYPE.CLOSE_TWO_FACTOR:
        return update(state, {
            formName: { $set: FORM_NAME.LOGIN },
            email: { $set: null },
            userId: { $set: null },
        });
    case ACTION_TYPE.SET_FORM_NAME:
        return update(state, {
            formName: { $set: action.value },
        });
    default:
        return state;
    }
};

const Login = () => {
    const { updateUserInformation } = useContext(UserContext);
    const [userName, setUserName] = useState();
    const [password, setPassword] = useState();
    const [state, dispatch] = useReducer(reducer, initialState);
    const classes = useStyles();

    const [{ data: result, loading }, executeLogin] = useAxios({ url: '/loginTwoFactorAuthentication', method: 'POST' }, { manual: true });

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            await executeLogin({
                data: {
                    username: userName,
                    password,
                },
            });
        } catch (error) {
            ModalUtils.errorMessage(error?.response?.data?.errors);
        }
    };

    useEffect(() => {
        const record = result?.data?.login;
        if (!isEmpty(record)) {
            dispatch({ type: ACTION_TYPE.SET_VALUE, value: record });
        }
    }, [result]);

    const setLogin = (loginResult) => {
        keyStore.save(loginResult);
        updateUserInformation(loginResult);
        return <Redirect to="/" />;
    };

    const setFormName = (value) => {
        dispatch({ type: ACTION_TYPE.SET_FORM_NAME, value });
    };

    return (
        <MainLayout>
            {state.formName === FORM_NAME.LOGIN && (
                <Form onSubmit={(e) => handleLogin(e)}>
                    <Form.Group controlId="formUserName">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Username"
                            required
                            onChange={
                                (e) => setUserName(e.target.value)
                            }
                        />
                    </Form.Group>

                    <Form.Group controlId="formPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            required
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>
                    <div className={classes.itemForgot}>
                        <span
                            className="login-form-forgot"
                            onClick={() => setFormName(FORM_NAME.FORGOT_PASSWORD)}
                        >
                            Reset Password
                        </span>
                    </div>
                    <Button variant="primary" type="submit">
                        {loading ? <CircularProgress size="1em" color="inherit" />
                            : (
                                <svg
                                    viewBox="64 64 896 896"
                                    focusable="false"
                                    className=""
                                    data-icon="login"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    {/* eslint-disable-next-line max-len */}
                                    <path d="M521.7 82c-152.5-.4-286.7 78.5-363.4 197.7-3.4 5.3.4 12.3 6.7 12.3h70.3c4.8 0 9.3-2.1 12.3-5.8 7-8.5 14.5-16.7 22.4-24.5 32.6-32.5 70.5-58.1 112.7-75.9 43.6-18.4 90-27.8 137.9-27.8 47.9 0 94.3 9.3 137.9 27.8 42.2 17.8 80.1 43.4 112.7 75.9 32.6 32.5 58.1 70.4 76 112.5C865.7 417.8 875 464.1 875 512c0 47.9-9.4 94.2-27.8 137.8-17.8 42.1-43.4 80-76 112.5s-70.5 58.1-112.7 75.9A352.8 352.8 0 0 1 520.6 866c-47.9 0-94.3-9.4-137.9-27.8A353.84 353.84 0 0 1 270 762.3c-7.9-7.9-15.3-16.1-22.4-24.5-3-3.7-7.6-5.8-12.3-5.8H165c-6.3 0-10.2 7-6.7 12.3C234.9 863.2 368.5 942 520.6 942c236.2 0 428-190.1 430.4-425.6C953.4 277.1 761.3 82.6 521.7 82zM395.02 624v-76h-314c-4.4 0-8-3.6-8-8v-56c0-4.4 3.6-8 8-8h314v-76c0-6.7 7.8-10.5 13-6.3l141.9 112a8 8 0 0 1 0 12.6l-141.9 112c-5.2 4.1-13 .4-13-6.3z" />
                                </svg>
                            )}
                        <span className="icon">Sign In</span>
                    </Button>
                </Form>
            )}
            {state.formName === FORM_NAME.TWO_FACTOR_AUTHENTICATION && (
                <TwoFactorAuthenticationForm
                    onClose={() => dispatch({ type: ACTION_TYPE.CLOSE_TWO_FACTOR })}
                    record={{ ...state, password }}
                    setLogin={setLogin}
                />
            )}

            {state.formName === FORM_NAME.FORGOT_PASSWORD && (
                <ForgotPasswordForm
                    onClose={() => setFormName(FORM_NAME.LOGIN)}
                />
            )}
        </MainLayout>
    );
};

export default Login;
