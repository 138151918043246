import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
    makeStyles, Avatar, Tooltip,
} from '@material-ui/core';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { isEmpty } from 'lodash';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    date: {
        marginRight: theme.spacing(0.8),
    },
    edited: {
        marginRight: theme.spacing(0.8),
        letterSpacing: 0,
        color: '#9a9a9a',
    },
}));

const CreatedOnWithAvatar = ({
    record, hiddenDate, className,
}) => {
    const classes = useStyles();
    const placeholder = '--';
    const createdBy = record.createdBy || {};
    const modifiedBy = record.modifiedBy || {};
    const recordIsEdited = DateUtils.isValid(record.modifiedOn) && DateUtils.isValid(record.createdOn) && !moment(record.modifiedOn).isSame(record.createdOn);
    const fullNameCreatedOn = `${createdBy.firstName} ${createdBy.lastName}`;
    const fullNameModifiedOn = `${modifiedBy.firstName} ${modifiedBy.lastName}`;
    const recordModifiedBy = !isEmpty(modifiedBy) && (createdBy.userId !== modifiedBy.userId);

    return (
        <div className={clsx('d-flex-center', className)}>
            {!hiddenDate && <span className={classes.date}>{DateUtils.format(record.createdOn, DateFormat.LONG_DATE_TIME_AM_PM) || placeholder}</span>}
            {recordIsEdited
            && (
                <Tooltip title={DateUtils.format(record.modifiedOn, DateFormat.LONG_DATE_TIME_AM_PM)}>
                    <span className={classes.edited}>(edited)</span>

                </Tooltip>
            )}
            {!isEmpty(createdBy) && (
                <AvatarGroup max={2}>
                    <Tooltip title={fullNameCreatedOn}>
                        <Avatar
                            alt={fullNameCreatedOn}
                            src={`${createdBy.avatar}`}
                            className={classes.avatar}
                        />
                    </Tooltip>
                    {recordModifiedBy && (
                        <Tooltip title={fullNameModifiedOn}>
                            <Avatar
                                alt={fullNameModifiedOn}
                                src={`${modifiedBy.avatar}`}
                                className={classes.avatar}
                            />
                        </Tooltip>
                    )}
                </AvatarGroup>
            )}
        </div>
    );
};

CreatedOnWithAvatar.propTypes = {
    record: PropTypes.object,
    hiddenDate: PropTypes.bool,
    className: PropTypes.string,
};

CreatedOnWithAvatar.defaultProps = {
    record: {},
    hiddenDate: false,
    className: null,
};

export default CreatedOnWithAvatar;
