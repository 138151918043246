import React, { Component } from 'react';

// Components and Others
import clsx from 'clsx';
import PropTypes from 'prop-types';
import KeyStore from 'utils/KeyStore';
import Permissions from 'utils/enum/Permissions';
import Dropdown from 'components/widgets/dropdown';
import { FormsActions } from 'utils/enum/DealEnum';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import FormPacksContainer from 'components/containers/deals/read/forms/FormPacksContainer';

// Material UI
import AddIcon from '@material-ui/icons/Add';
import FormTab from 'styles/modules/deals/FormsTab';
import { withStyles } from '@material-ui/core/styles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Typography, IconButton } from '@material-ui/core';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import CreateFormPacks from 'components/modules/deals/create/forms/CreateFormPacks';

const styles = (theme) => FormTab.formPacks(theme);

class FormPacks extends Component {
    renderList() {
        const {
            records, classes, packSelected, onSelectFormPacks,
            onSelectAction, getActions, hiddenFormPack, record,
            action,
        } = this.props;
        const actions = getActions();
        const formsWrite = KeyStore.isAllowed(Permissions.SALES_DEAL_FORMS_WRITE);

        return records.map((item, index) => {
            const itemSelected = item?.packId === packSelected?.packId ? 'active' : '';
            const arrowSelected = formsWrite ? '' : 'active';

            if (action === FormsActions.RENAME.toUpperCase() && record?.packId === item?.packId) {
                return <CreateFormPacks hiddenFormPack={hiddenFormPack} records={records} key={index} action={action} record={item} />;
            }

            return (
                <div className={clsx(classes.contentItem, itemSelected)} key={index} onClick={() => onSelectFormPacks(item)}>
                    <Typography className={classes.item} noWrap>{item?.packName}</Typography>
                    <div className="actions">
                        {formsWrite && (
                            <Dropdown
                                pullRight
                                actions={actions}
                                onSelect={(e) => onSelectAction(e.value, item)}
                                icon={<MoreHorizIcon fontSize="small" />}
                                classButton={classes.buttonActions}
                            />
                        )}
                        {item?.packId === packSelected?.packId && <PlayArrowRoundedIcon className={clsx(arrowSelected, 'play-arrow')} />}
                    </div>
                </div>
            );
        });
    }

    render() {
        const {
            classes, addFormPack, action,
            hiddenFormPack, records, record,
            onDeleteFormPack,
        } = this.props;
        const isAdd = action === FormsActions.Add.toUpperCase();
        const isDuplicate = action === FormsActions.DUPLICATE.toUpperCase();
        const open = action === FormsActions.DELETE.toUpperCase();

        return (
            <div className={clsx(classes.main, 'd-flex-column')}>
                <div className={classes.header}>
                    <Typography variant="h5" className={classes.title}>Form Packs</Typography>
                    {KeyStore.isAllowed(Permissions.SALES_DEAL_FORMS_WRITE) && (
                        <IconButton aria-label="add" className={classes.margin} size="small" onClick={addFormPack} disabled={isAdd}>
                            <AddIcon fontSize="small" />
                        </IconButton>
                    )}
                </div>
                <div className={classes.body}>
                    {this.renderList()}
                    {(isAdd || isDuplicate) && <CreateFormPacks hiddenFormPack={hiddenFormPack} records={records} action={action} record={record} />}
                </div>
                <ConfirmDialog
                    title="Confirm remove Form Pack"
                    description="Are you sure you want to delete this Form Pack?"
                    open={open}
                    variant="outlined"
                    titlePrimary="Yes"
                    titleSecondary="No"
                    onClose={hiddenFormPack}
                    onClickSecondary={hiddenFormPack}
                    onClickPrimary={onDeleteFormPack}
                />
            </div>
        );
    }
}

FormPacks.propTypes = {
    record: PropTypes.object,
    packSelected: PropTypes.object,
    action: PropTypes.string.isRequired,
    records: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    getActions: PropTypes.func.isRequired,
    addFormPack: PropTypes.func.isRequired,
    onSelectAction: PropTypes.func.isRequired,
    hiddenFormPack: PropTypes.func.isRequired,
    onDeleteFormPack: PropTypes.func.isRequired,
    onSelectFormPacks: PropTypes.func.isRequired,
};

FormPacks.defaultProps = {
    record: null,
    packSelected: null,
};

export default withStyles(styles)(FormPacksContainer(FormPacks));
