import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { isValidField } from 'utils/schema/utils';
import DealStyles from 'styles/modules/DealStyles';
import { Col, Form } from 'react-bootstrap';
import {
    DealStructureFields, DealType, TagType,
} from 'utils/enum/DealEnum';
import DateUtils from 'lib/DateUtils';
import DealUtils from 'utils/DealUtils';
import StringUtils from 'lib/StringUtils';
import NumberUtils from 'lib/NumberUtils';
import DatePicker from 'react-datepicker';
import Select from 'components/widgets/Select';
import { makeStyles, fade } from '@material-ui/core/styles';
import { Typography, Grid, Tooltip } from '@material-ui/core';
import InputNumber from 'components/widgets/InputNumber';
import { ReactComponent as CheckIcon } from 'assets/check.svg';
import { ReactComponent as UnCheckIcon } from 'assets/uncheck.svg';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import InputNumberWithButton from 'components/widgets/InputNumberWithButton';

const useStyle = makeStyles((theme = {}) => DealStyles.dealStructure(theme, fade));

const LeaseDealStructure = ({
    isReadOnly,
    labels,
    toggleModalDealStructure,
    data,
    vendorList,
    leadSourceList,
    formValidations,
    onChangeValue,
    onChangeFloat,
    onChangeSoldDate,
    onChangeDayFirstPaymentDue,
    onChangeDateFirstPaymentDue,
}) => {
    const classes = useStyle();

    const renderReadOnlyMode = () => {
        const {
            dealType, soldDate, totalFees, interestRate, totalTradeAllowance,
            buyRate, assigned, balanceDue, totalTradePayOff, leadSource, grossCapitalizedCost,
            cashDownPayment, discount, firstPaymentDue, salesTax, salesPrice, totalProductsPrice,
            cashDueAtSigning, term, contractAnnualMileage, leasePaymentAmount, leasePaymentTax,
            totalTitleRegistration, financeCompany, bankOriginAmount, registerOutState, residualValue, totalDeferredDownPayment,
        } = data;
        const currentSoldDate = DateUtils.getOnlyDate(soldDate || '');
        const currentFirstPaymentDue = DateUtils.format(firstPaymentDue || '');

        return (
            <Grid container spacing={2}>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.dealType}:`}</Typography>
                    <Typography>{dealType || ''}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.soldDate}:`}</Typography>
                    <Typography>{currentSoldDate}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.leadSource}:`}</Typography>
                    <Typography>{leadSource}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.leaseAmount}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(balanceDue)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.price}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(salesPrice)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.lender}:`}</Typography>
                    <Typography noWrap>{financeCompany || ''}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.fees}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(totalFees)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.moneyFactor}:`}</Typography>
                    <Typography>{NumberUtils.applyPercentageFormat(interestRate, '0.0000%')}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.vscGapProducts}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(totalProductsPrice)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.buyRate}:`}</Typography>
                    <Typography>{NumberUtils.applyPercentageFormat(buyRate)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.trade}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(totalTradeAllowance)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.term}:`}</Typography>
                    <Typography>{term || ''}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.payoff}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(totalTradePayOff)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.firstPaymentDue}:`}</Typography>
                    <Typography>{currentFirstPaymentDue || ''}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.capCostReduction}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(cashDownPayment)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.deferredDownPayment}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(totalDeferredDownPayment)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.salesTax}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(salesTax)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.paymentLease}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(leasePaymentAmount + leasePaymentTax)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.titleRegistration}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(totalTitleRegistration)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.discount}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(discount)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.residual}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(residualValue)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.originationFee}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(bankOriginAmount)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.annualMileage}:`}</Typography>
                    <Typography>{NumberUtils.applyThousandsFormat(contractAnnualMileage)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.ricAssignment}:`}</Typography>
                    <Typography>{assigned || ''}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.grossCapitalizedCost}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(grossCapitalizedCost)}</Typography>
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.registerOutOfState}:`}</Typography>
                    {
                        registerOutState
                            ? <CheckIcon className={classes.checkIcon} />
                            : <UnCheckIcon className={classes.checkIcon} />
                    }
                </Grid>
                <Grid item xs={6} container className={classes.columnFields}>
                    <Typography color="primary" variant="h6">{`${labels.cashDueAtSigning}:`}</Typography>
                    <Typography>{NumberUtils.applyCurrencyFormat(cashDueAtSigning)}</Typography>
                </Grid>
            </Grid>
        );
    };

    const renderForm = () => {
        const {
            soldDate, firstPaymentDue, dealType, salesPrice, totalFees, leadSource,
            totalProductsPrice, interestRate, totalTradeAllowance, buyRate, reserveAmount, reserveOverride,
            term, paymentFrequency, salesTax, firstPaymentDueDays, residualValue, adjustLastPayment,
            cashDownPayment, discount, discountOverride, balanceDue, contractAnnualMileage,
            assigned, registerOutState, tagPlate, tagPlateType, bankOriginAmount, regZAPR, totalTitleRegistration,
            financeCompany, totalTradePayOff, maxRate, paymentOverride, balanceDueOverride,
            grossCapitalizedCost, cashDueAtSigning, leasePaymentAmount, leasePaymentTax, totalDeferredDownPayment,
        } = data;

        let isCashOrWholesale = false;
        let isWholesale = false;
        if (dealType) {
            const type = dealType.toUpperCase();
            isCashOrWholesale = type === DealType.CASH.toUpperCase()
                || type === DealType.WHOLESALE.toUpperCase();
            isWholesale = type === DealType.WHOLESALE.toUpperCase();
        }

        const { errors } = formValidations;

        return (
            <Form className={clsx(classes.formW100, 'am-form ')}>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.dealType}</Form.Label>
                        <Select
                            className={isValidField(errors, 'dealType') ? 'invalid-field' : ''}
                            name="dealType"
                            onChange={onChangeValue}
                            value={dealType}
                            placeholder="Select"
                            options={DealUtils.getDealType()}
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col} className="full-width-date-picker">
                        <Form.Label>{labels.soldDate}</Form.Label>
                        <DatePicker
                            className={clsx('form-control', 'form-control-sm', isValidField(errors, 'soldDate') ? 'invalid-field' : '')}
                            onChange={(date) => onChangeSoldDate(date)}
                            selected={soldDate}
                            popperContainer={CalendarContainer}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.leadSource}</Form.Label>
                        <Select
                            className={isValidField(errors, 'leadSource') ? 'invalid-field' : ''}
                            name="leadSource"
                            onChange={onChangeValue}
                            value={leadSource}
                            placeholder="Select"
                            options={leadSourceList}
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.leaseAmount}</Form.Label>
                        <InputNumberWithButton
                            className={isValidField(errors, 'balanceDue') ? 'invalid-field' : ''}
                            value={balanceDue}
                            showCurrency
                            allowNegative
                            thousandSeparator
                            fixedDecimalScale
                            onClick={() => toggleModalDealStructure(DealStructureFields.AMOUNT_FINANCED)}
                            placeholder="$0.00"
                            disableButton={isCashOrWholesale}
                            size="sm"
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.price}</Form.Label>
                        <InputNumber
                            className={isValidField(errors, 'salesPrice') ? 'invalid-field' : ''}
                            disabled={!StringUtils.isEmpty(balanceDueOverride)}
                            showCurrency
                            thousandSeparator
                            placeholder="$0.00"
                            value={salesPrice}
                            onChange={(value) => onChangeFloat('salesPrice', value)}
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.lender}</Form.Label>
                        <Select
                            className={isValidField(errors, 'financeCompany') ? 'invalid-field' : ''}
                            sorted
                            disabled={isCashOrWholesale}
                            name="financeCompany"
                            onChange={onChangeValue}
                            value={financeCompany}
                            options={vendorList}
                            placeholder="Select"
                            size="sm"
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} className="col-md-6">
                        <Form.Label>{labels.fees}</Form.Label>
                        <InputNumberWithButton
                            value={totalFees}
                            showCurrency
                            thousandSeparator
                            placeholder="$0.00"
                            onClick={() => toggleModalDealStructure(DealStructureFields.FEES)}
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.moneyFactor}</Form.Label>
                        <InputNumber
                            className={
                                clsx(maxRate && interestRate > maxRate ? 'invalid-field' : '', isValidField(errors, 'dealType') ? 'invalid-field' : '')
                            }
                            onChange={(value) => onChangeFloat('interestRate', value)}
                            value={interestRate}
                            placeholder="0"
                            decimalScale={4}
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.apr}</Form.Label>
                        <InputNumber
                            className={isValidField(errors, 'regZAPR') ? 'invalid-field' : ''}
                            placeholder="0"
                            decimalScale={4}
                            disabled
                            value={regZAPR}
                            size="sm"
                        />
                    </Form.Group>
                </Form.Row>
                {maxRate && (
                    <Form.Row className={classes.maxRateRow}>
                        <Form.Group as={Col} className="d-flex-center justify-content-center">
                            <div className="text-danger">
                                { `MaxRate: ${maxRate}`}
                            </div>
                        </Form.Group>
                    </Form.Row>
                )}
                <Form.Row>
                    <Form.Group as={Col} className="col-md-6">
                        <Form.Label>{labels.vscGapProducts}</Form.Label>
                        <InputNumber
                            value={totalProductsPrice}
                            placeholder="$0.00"
                            showCurrency
                            thousandSeparator
                            disabled
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.buyRate}</Form.Label>
                        <InputNumber
                            className={isValidField(errors, 'buyRate') ? 'invalid-field' : ''}
                            onChange={(value) => onChangeFloat('buyRate', value)}
                            value={buyRate}
                            placeholder="0"
                            disabled={isCashOrWholesale}
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.reserve}</Form.Label>
                        <div className={classes.flexCenter}>
                            <InputNumber
                                onChange={(value) => onChangeFloat('reserveAmount', value)}
                                value={reserveAmount}
                                placeholder="$0.00"
                                showCurrency
                                thousandSeparator
                                disabled={!reserveOverride}
                                size="sm"
                            />
                            {/** //TODO: Pending change border color to the checkbox */}
                            <Form.Check
                                onChange={(e) => onChangeValue('reserveOverride', e.target.checked)}
                                checked={reserveOverride}
                                className={classes.alignCheckBox}
                            />
                        </div>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.trade}</Form.Label>
                        <InputNumber
                            value={totalTradeAllowance}
                            placeholder="$0.00"
                            disabled
                            thousandSeparator
                            showCurrency
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.payoff}</Form.Label>
                        <InputNumber
                            value={totalTradePayOff}
                            placeholder="$0.00"
                            disabled
                            thousandSeparator
                            showCurrency
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.term}</Form.Label>
                        <InputNumber
                            className={isValidField(errors, 'term') ? 'invalid-field' : ''}
                            onChange={(value) => onChangeFloat('term', value)}
                            value={term}
                            placeholder="0"
                            decimalScale={0}
                            disabled={isCashOrWholesale || paymentOverride}
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label className="blank-label" />
                        <Select
                            className={isValidField(errors, 'paymentFrequency') ? 'invalid-field' : ''}
                            name="paymentFrequency"
                            value={paymentFrequency}
                            onChange={onChangeValue}
                            options={[
                                {
                                    value: 'Monthly',
                                    label: 'Monthly',
                                },
                                {
                                    value: 'Semi-Monthly',
                                    label: 'Semi-Monthly',
                                },
                                {
                                    value: 'Weekly',
                                    label: 'Weekly',
                                },
                                {
                                    value: 'Bi-Weekly',
                                    label: 'Bi-Weekly',
                                },
                            ]}
                            placeholder="Select"
                            disabled={isCashOrWholesale}
                            size="sm"
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} className="col-md-6">
                        <Form.Label>{labels.capCostReduction}</Form.Label>
                        <InputNumber
                            className={isValidField(errors, 'cashDownPayment') ? 'invalid-field' : ''}
                            disabled={!StringUtils.isEmpty(balanceDueOverride)}
                            onChange={(value) => onChangeFloat('cashDownPayment', value)}
                            value={cashDownPayment}
                            placeholder="$0.00"
                            thousandSeparator
                            showCurrency
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.firstPaymentDue}</Form.Label>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <InputNumber
                                    onChange={(value) => onChangeDayFirstPaymentDue(value)}
                                    value={firstPaymentDueDays}
                                    placeholder="0"
                                    disabled={isCashOrWholesale}
                                    decimalScale={0}
                                    size="sm"
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <DatePicker
                                    className={clsx('form-control', 'form-control-sm', isValidField(errors, 'firstPaymentDue') ? 'invalid-field' : '')}
                                    minDate={soldDate}
                                    popperContainer={CalendarContainer}
                                    onChange={(date) => onChangeDateFirstPaymentDue(date)}
                                    selected={firstPaymentDue}
                                    disabled={isCashOrWholesale}
                                />
                            </Form.Group>
                        </Form.Row>
                    </Form.Group>
                </Form.Row>
                <Form.Row className={classes.finalOddPaymentRow}>
                    <Form.Group as={Col} />
                    <Form.Group as={Col} className={classes.finalOddPaymentContainer}>
                        <Form.Label>Final Odd Payment</Form.Label>
                        <Tooltip title="If this switch is on, the final payment may be adjusted by rounding.">
                            <Form className={clsx(classes.switchMargin, 'am-form')}>
                                <Form.Check
                                    label=""
                                    type="switch"
                                    id="adjust-last-payment-switch"
                                    checked={adjustLastPayment}
                                    onChange={(e) => onChangeValue('adjustLastPayment', e.target.checked)}
                                />
                            </Form>
                        </Tooltip>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.deferredDownPayment}</Form.Label>
                        <InputNumberWithButton
                            className={isValidField(errors, 'totalDeferredDownPayment') ? 'invalid-field' : ''}
                            value={totalDeferredDownPayment}
                            onClick={() => toggleModalDealStructure(DealStructureFields.DEFERRED_DOWN_PAYMENT)}
                            placeholder="$0.00"
                            thousandSeparator
                            showCurrency
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.paymentLease}</Form.Label>
                        <InputNumberWithButton
                            className={isValidField(errors, 'paymentAmount') ? 'invalid-field' : ''}
                            value={leasePaymentAmount + leasePaymentTax}
                            showCurrency
                            thousandSeparator
                            fixedDecimalScale
                            placeholder="$0.00"
                            disableButton={isCashOrWholesale}
                            size="sm"
                            onClick={() => (toggleModalDealStructure(DealStructureFields.LEASE_PAYMENT, 'Payment'))}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.salesTax}</Form.Label>
                        <InputNumberWithButton
                            className={isValidField(errors, 'salesTax') ? 'invalid-field' : ''}
                            value={salesTax}
                            showCurrency
                            thousandSeparator
                            fixedDecimalScale
                            placeholder="$0.00"
                            onClick={() => toggleModalDealStructure(DealStructureFields.SALES_TAX)}
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.discount}</Form.Label>
                        <div className={classes.flexCenter}>
                            <InputNumber
                                onChange={(value) => onChangeFloat('discount', value)}
                                value={discount}
                                placeholder="$0.00"
                                showCurrency
                                thousandSeparator
                                disabled={!discountOverride}
                                size="sm"
                            />
                            {/** //TODO: Pending change border color to the checkbox */}
                            <Form.Check
                                checked={discountOverride}
                                onChange={(e) => onChangeValue('discountOverride', e.target.checked)}
                                className={classes.alignCheckBox}
                            />
                        </div>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.titleRegistration}</Form.Label>
                        <InputNumberWithButton
                            value={totalTitleRegistration}
                            onClick={() => toggleModalDealStructure(DealStructureFields.TITLE_REGISTRATION, 'Tag & Title')}
                            placeholder="$0.00"
                            thousandSeparator
                            showCurrency
                            disableButton={isWholesale}
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.originationFee}</Form.Label>
                        <InputNumber
                            placeholder="$0.00"
                            thousandSeparator
                            showCurrency
                            onChange={(value) => onChangeFloat('bankOriginAmount', value)}
                            value={bankOriginAmount}
                            disabled={isCashOrWholesale}
                            size="sm"
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.residual}</Form.Label>
                        <InputNumber
                            onChange={(value) => onChangeFloat('residualValue', value)}
                            value={residualValue}
                            placeholder="$0.00"
                            showCurrency
                            thousandSeparator
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.ricAssignment}</Form.Label>
                        <Select
                            name="assigned"
                            onChange={onChangeValue}
                            value={assigned}
                            options={[
                                {
                                    value: 'Assigned without recourse',
                                    label: 'Assigned without recourse',
                                },
                                {
                                    value: 'Assigned with recourse',
                                    label: 'Assigned with recourse',
                                },
                                {
                                    value: 'Assigned with limited recourse',
                                    label: 'Assigned with limited recourse',
                                },
                            ]}
                            placeholder="Select"
                            size="sm"
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.annualMileage}</Form.Label>
                        <InputNumberWithButton
                            value={contractAnnualMileage}
                            thousandSeparator
                            fixedDecimalScale
                            placeholder="0.00"
                            size="sm"
                            disableButton={isCashOrWholesale}
                            onClick={() => (!isCashOrWholesale ? toggleModalDealStructure(DealStructureFields.LEASE_MILEAGE, 'Anual Mileage') : null)}
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>{labels.plate}</Form.Label>
                                <Form.Control
                                    onChange={(e) => onChangeValue('tagPlate', e.target.value)}
                                    value={tagPlate || ''}
                                    type="text"
                                    placeholder=""
                                    size="sm"
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>{labels.type}</Form.Label>
                                <Select
                                    name="tagPlateType"
                                    onChange={onChangeValue}
                                    value={tagPlateType}
                                    options={[
                                        {
                                            value: TagType.NEW,
                                            label: TagType.NEW,
                                        },
                                        {
                                            value: TagType.TRANSFER,
                                            label: TagType.TRANSFER,
                                        },
                                    ]}
                                    placeholder="Select"
                                    size="sm"
                                />
                            </Form.Group>
                        </Form.Row>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.grossCapitalizedCost}</Form.Label>
                        <InputNumber
                            value={grossCapitalizedCost}
                            placeholder="$0.00"
                            showCurrency
                            thousandSeparator
                            disabled
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col} className={classes.flexCenter}>
                        <Form.Label className={classes.marginBottom0}>{labels.registerOutOfState}</Form.Label>
                        {/** //TODO: Pending change border color to the checkbox */}
                        <Form.Check
                            onChange={(e) => onChangeValue('registerOutState', e.target.checked)}
                            checked={registerOutState}
                            className={classes.alignCheckBox}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>{labels.cashDueAtSigning}</Form.Label>
                        <InputNumber
                            value={cashDueAtSigning}
                            placeholder="$0.00"
                            showCurrency
                            thousandSeparator
                            disabled
                            size="sm"
                        />
                    </Form.Group>
                    <Form.Group as={Col} className={classes.flexCenter} />
                </Form.Row>
            </Form>
        );
    };

    return isReadOnly ? renderReadOnlyMode() : renderForm();
};

LeaseDealStructure.propTypes = {
    isReadOnly: PropTypes.bool,
    labels: PropTypes.object,
    toggleModalDealStructure: PropTypes.func,
    data: PropTypes.object,
    vendorList: PropTypes.arrayOf(PropTypes.shape({
        vendorId: PropTypes.number,
        vendorName: PropTypes.string,
    })).isRequired,
    formValidations: PropTypes.object,
    leadSourceList: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    })).isRequired,
    onChangeValue: PropTypes.func.isRequired,
    onChangeFloat: PropTypes.func.isRequired,
    onChangeSoldDate: PropTypes.func.isRequired,
    onChangeDayFirstPaymentDue: PropTypes.func.isRequired,
    onChangeDateFirstPaymentDue: PropTypes.func.isRequired,
};

LeaseDealStructure.defaultProps = {
    isReadOnly: true,
    labels: {},
    toggleModalDealStructure: null,
    data: {},
    formValidations: {},
};

export default LeaseDealStructure;
