import * as Sentry from '@sentry/react';
import { useApolloClient } from '@apollo/client';
import { FetchPolicy, WATERMARK_SPOTS } from 'utils/enum/Core';
import ModalUtils from 'utils/ModalUtils';
import ImagesUtils from 'utils/ImagesUtils';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';
import { SpecialRegularExpression } from 'utils/enum/InventoryEnum';

const useImagesActions = () => {
    const client = useApolloClient();

    const moveType = {
        NEXT: 'next',
        PREVIOUS: 'previous',
    };

    const createThumbnail = async (path, stockNumber) => {
        try {
            const { data: setThumbnailResponse } = await client.mutate({
                mutation: InventoryMutation.SET_VEHICLE_THUMBNAIL,
                variables: {
                    stockNumber,
                    path,
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            const response = setThumbnailResponse?.setVehicleThumbnail;
            if (response) {
                return { success: response.success, thumbnail: response.thumbnail };
            }

            Sentry.captureMessage(`The thumbnail could not be created for stock: ${stockNumber}. Path: ${path}`);
            return null;
        } catch (error) {
            Sentry.captureException(error);
            return null;
        }
    };

    const deleteImages = async (
        imagesSource,
        variableAsId,
        mutation,
        imagesToDelete,
        successCallback,
        imagesLoaderCallback,
        imagesLoaderCallbackPayload,
        isAdvertisingTab = false,
        imagesSaveCallback,
        stockNumber,
        thumbnail,
    ) => {
        if (imagesToDelete.length === 0) return;

        const ids = [];
        const paths = [];

        if (isAdvertisingTab) {
            imagesToDelete.forEach((index) => {
                const imageToRemove = imagesSource[index];
                const { pathname } = new URL(imageToRemove);
                paths.push(pathname);
            });
        } else {
            imagesToDelete.forEach((index) => {
                ids.push(imagesSource[index][variableAsId]);
            });
        }

        try {
            const { data } = await client.mutate({
                mutation,
                variables: isAdvertisingTab ? {
                    stockNumber,
                    paths,
                } : {
                    ids,
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            if (data) {
                if (isAdvertisingTab) {
                    const cleanImages = imagesSource
                        .filter((url) => !imagesToDelete.some((index) => imagesSource[index] === url));

                    let newThumbnail = thumbnail;
                    if (cleanImages.length > 0) {
                        const firstImage = cleanImages[0];
                        const regExp = new RegExp(SpecialRegularExpression.IMAGES_BUCKET_MATCH);
                        const isHostedOnUs = regExp.test(firstImage);

                        if (isHostedOnUs) {
                            const { origin, pathname } = new URL(firstImage);
                            const startPositionToSlice = pathname.search(regExp) === -1
                                ? 1 : 2;
                            const path = pathname
                                .split('/')
                                .slice(startPositionToSlice)
                                .join('/');

                            const thumbnailRequest = await createThumbnail(path, stockNumber);
                            if (thumbnailRequest?.success) newThumbnail = `${origin}${thumbnailRequest.thumbnail}`;
                        }
                    }

                    imagesSaveCallback(cleanImages, newThumbnail);
                    return;
                }

                successCallback(true);
                imagesLoaderCallback(imagesLoaderCallbackPayload);
            }
        } catch (error) {
            ModalUtils.errorMessage(null, error.message);
        }
    };

    const uploadImage = async (
        file,
        propertyName,
        itemBeingEditedId,
        stockNumber,
        uploadingMutation,
        uploadingMutationName,
        replacement,
    ) => {
        try {
            const { size } = file;
            const maxFileSize = 512000; // 500KB

            let resized = null;
            if (!replacement && size > maxFileSize) {
                resized = await ImagesUtils.resizeImage(file);
            }

            const { data: savePhotoResponse } = await client.mutate({
                mutation: uploadingMutation,
                variables: {
                    stockNumber,
                    ...(propertyName ? { [propertyName]: itemBeingEditedId } : {}),
                    file: resized || file,
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            const response = savePhotoResponse?.[uploadingMutationName];
            if (response) {
                return { url: response.url, key: response.key };
            }
        } catch (error) {
            ModalUtils.errorMessage(null, error.message);
        }

        return null;
    };

    const addImages = async (
        files,
        dispatcher,
        dispatcherPayload,
        additionalDispatcherPayload,
        propertyName,
        itemBeingEditedId,
        stockNumber,
        uploadingMutation,
        uploadingMutationName,
        savingMutation,
        imagesLoaderCallback,
        isAdvertisingTab = false,
        replacement = false,
        imagesCounter,
        thumbnail,
        imagesSource,
        imagesToReplace,
    ) => {
        if (files && files.length > 0) {
            if (isAdvertisingTab) {
                const currentTotal = 60 - imagesCounter;
                if (!replacement && files.length > currentTotal) {
                    ModalUtils.errorMessage(null, `The number of selected photos exceeds the limit: ${currentTotal}`);
                    return;
                }
            }

            const maxAllowedFileSize = ImagesUtils.MAX_SIZES_WITH_ERROR.IMAGES.SIZE;
            const anyNotAllowedFile = files.some((file) => file.size > maxAllowedFileSize);
            if (anyNotAllowedFile) {
                ModalUtils.errorMessage(null, ImagesUtils.MAX_SIZES_WITH_ERROR.IMAGES.ERROR);
                return;
            }

            if (!isAdvertisingTab || (isAdvertisingTab && !replacement)) dispatcher(dispatcherPayload);
            let thumbnailResponse = null;
            const uploadedImages = await Promise.all(files.map(async (file, index) => {
                const uploaded = await uploadImage(
                    file,
                    propertyName,
                    itemBeingEditedId,
                    stockNumber,
                    uploadingMutation,
                    uploadingMutationName,
                    replacement,
                );
                if (uploaded) {
                    if (isAdvertisingTab) {
                        const { origin } = new URL(uploaded.url);
                        if (
                            (
                                (imagesCounter === 0 && index === 0)
                                || (
                                    replacement
                                    && index === (imagesToReplace || []).findIndex((value) => value === 0)
                                )
                            )
                            && uploaded
                        ) {
                            const thumbnailRequest = await createThumbnail(uploaded.key, stockNumber);
                            thumbnailResponse = thumbnailRequest?.success
                                ? `${origin}${thumbnailRequest.thumbnail}`
                                : thumbnail;
                        }
                    }

                    return uploaded.url;
                }

                return null;
            }));

            try {
                if (isAdvertisingTab) {
                    if (replacement) {
                        const clone = [...imagesSource];
                        imagesToReplace.forEach((value, index) => {
                            clone[value] = uploadedImages[index];
                        });

                        imagesLoaderCallback(clone, thumbnailResponse);
                    } else {
                        imagesLoaderCallback([...imagesSource, ...uploadedImages], thumbnailResponse);
                    }

                    return;
                }

                const { data } = await client.mutate({
                    mutation: savingMutation,
                    variables: {
                        [propertyName]: itemBeingEditedId,
                        images: uploadedImages,
                    },
                    fetchPolicy: FetchPolicy.NO_CACHE,
                });

                if (data) {
                    dispatcher(additionalDispatcherPayload || dispatcherPayload);
                    imagesLoaderCallback({
                        variables: {
                            [propertyName]: itemBeingEditedId,
                        },
                    });
                }
            } catch (error) {
                ModalUtils.errorMessage(null, error.message);
            }
        }
    };

    const setPhotoIndex = (
        type,
        photoIndex,
        selectedImages,
        dispatcher,
        actionType,
        sendInnerCustomValue = false,
    ) => {
        const { length } = selectedImages;
        let index = 0;

        if (type === moveType.NEXT) {
            index = (photoIndex + 1) % length;
        } else if (type === moveType.PREVIOUS) {
            index = (photoIndex + length - 1) % length;
        }

        dispatcher(actionType ? {
            type: actionType,
            value: sendInnerCustomValue ? { photoIndex: index } : index,
        } : (state) => ({
            ...state,
            photoIndex: index,
        }));
    };

    const watermarkImages = async (
        imagesToWatermark,
        imagesSource,
        dispatcher,
        dispatcherPayload,
        selectedWatermark,
        successCallback,
    ) => {
        if (imagesToWatermark.length === 0) return;

        try {
            dispatcher(dispatcherPayload);
            const promises = [];
            imagesToWatermark.forEach((index) => {
                const imageURL = imagesSource[index];
                promises.push(ImagesUtils.loadImageUrlIntoFile(imageURL));
            });
            const loadedFiles = await Promise.all(promises);
            promises.splice(0, promises.length);

            loadedFiles.forEach((file) => {
                promises.push(
                    ImagesUtils.addWatermarks(file, [{
                        url: selectedWatermark,
                        spot: WATERMARK_SPOTS.OVERLAY,
                        padding: 0,
                    }]),
                );
            });
            const watermarkedImages = await Promise.all(promises);
            successCallback(watermarkedImages, true);
        } catch (error) {
            dispatcher(dispatcherPayload);
            ModalUtils.errorMessage(null, error.message);
        }
    };

    return {
        moveType,
        deleteImages,
        addImages,
        setPhotoIndex,
        createThumbnail,
        watermarkImages,
        uploadImage,
    };
};

export default useImagesActions;
