import { gql } from '@apollo/client';

export default class OpportunityMutation {
    static UPDATE_OPPORTUNITY_TRADE_IN = gql`
        mutation updateTradeInOpportunity($crmId: ID! $input: TradeInOpportunity!) {
            updateTradeInOpportunity(crmId: $crmId input: $input)
        }
    `;

    static CREATE_OPPORTUNITY = gql`
        mutation createOpportunity($input: OpportunityCreateInput!) {
            createOpportunity(input: $input) {
                crmId
                success
            }
        }
    `;

    static UPDATE_OPPORTUNITY_BASIC_INFORMATION = gql`
        mutation updateOpportunityBasicInformation($crmId: ID!, $input: OpportunityBasicInformation!) {
            updateOpportunityBasicInformation(crmId: $crmId, input: $input)
        }
    `;

    static UPDATE_VEHICLE_OF_INTEREST = gql`
        mutation updateVehicleOfInterest($crmId: ID!, $input: VehicleOfInterestInput!) {
            updateVehicleOfInterest(crmId: $crmId, input: $input)
        }
    `;

    static ASSIGN_PROSPECT = gql`
        mutation assignProspect($crmId: ID!, $customerId: ID!) {
            assignProspect(crmId: $crmId, customerId: $customerId)
        }
    `;

    static ASSIGN_CO_PROSPECT = gql`
        mutation assignCoProspect($crmId: ID!, $customerId: ID!) {
            assignCoProspect(crmId: $crmId, customerId: $customerId)
        }
    `;

    static UNASSIGN_CO_PROSPECT = gql`
        mutation unassignCoProspect($crmId: ID!) {
            unassignCoProspect(crmId: $crmId)
        }
    `;

    static PRINT_PROPOSAL_FORM = gql`
        mutation printProposalForm($opportunityId: ID!, $form: customFieldInput!, $lotId: Int!) {
            printProposalForm(opportunityId: $opportunityId, form: $form, lotId: $lotId)
        }
    `;

    static SET_PROCESS_STAGE = gql`
        mutation setProcessStage($opportunityId: ID!, $processStage: String!) {
            setProcessStage(opportunityId: $opportunityId, processStage: $processStage )
        }
    `;

    static SET_OPPORTUNITY_STATUS = gql`
        mutation setOpportunityStatus($crmId: ID!, $status: String!, $lostReasonId: Int) {
            setOpportunityStatus(crmId: $crmId, status: $status, lostReasonId: $lostReasonId)
        }
    `;

    static TRANSFER_LOT = gql`
        mutation transferOpportunity($crmId: ID!, $lotName: String!) {
            transferOpportunity(crmId: $crmId, lotName: $lotName)
        }
    `;

    static PRINT_TEST_DRIVE_FORM = gql`
        mutation printTestDriveForm($opportunityId: ID!) {
            printTestDriveForm(opportunityId: $opportunityId)
        }
    `;

    static PRINT_CREDIT_APP_FORM = gql`
        mutation printCreditApp($opportunityId: ID!) {
            printCreditApp(opportunityId: $opportunityId)
        }
    `;

    static PRINT_PURCHASE_WORKSHEET_FORM = gql`
        mutation printPurchaseWorksheetForm($opportunityId: ID!, $form: FormPurchaseWorksheet!, $lotId: Int!) {
            printPurchaseWorksheetForm(opportunityId: $opportunityId, form: $form, lotId: $lotId)
        }
    `;

    static UPDATE_CRM_QUICK_NOTE= gql`
        mutation updateCRMQuickNote($crmId: ID!, $note: String!) {
            updateCRMQuickNote(crmId: $crmId note: $note)
        }
    `;

    static REASSIGN_OPPORTUNITY = gql`
        mutation reassignOpportunity($input: [ReassignOpportunityInput!]!) {
            reassignOpportunity(input: $input) {
                reassigned
            }
        }
    `;

    static SAVE_AUTOMATED_RESPONSE = gql`
        mutation saveAutomatedResponse($input: AutomatedResponseInput!) {
            saveAutomatedResponse(input: $input)
        }
    `;

    static SAVE_AUTOMATED_RESPONSE_NOTE_EXCLUSION = gql`
        mutation saveAutomatedResponseNoteExclusion($automatedResponseNoteExclusionId: String, $content: String!, $active: Boolean) {
            saveAutomatedResponseNoteExclusion(
                automatedResponseNoteExclusionId: $automatedResponseNoteExclusionId,
                content: $content,
                active: $active
            )
        }
    `;

    static SAVE_OBSTACLE_TO_CLOSE = gql`
        mutation saveObstacleToClose($obstacleToCloseId: Int, $description: String!, $active: Boolean) {
            saveObstacleToClose(
                obstacleToCloseId: $obstacleToCloseId,
                description: $description,
                active: $active
            )
        }
    `;

    static UPDATE_OPPORTUNITY_FUNNEL_STAGE = gql`
        mutation updateOpportunityFunnelStage($crmId: ID!, $stage: String!, $value: Boolean!, $customerLeaving: Boolean) {
            updateOpportunityFunnelStage(
                crmId: $crmId,
                stage: $stage,
                value: $value,
                customerLeaving: $customerLeaving
            )
        }
    `;

    static ENQUEUE_MANUAL_AUTOMATED_RESPONSE = gql`
        mutation enqueueManualAutomatedResponse($crmId: ID!, $automatedResponseId: String!) {
            enqueueManualAutomatedResponse(
                crmId: $crmId,
                automatedResponseId: $automatedResponseId
            )
        }
    `;

    static CANCEL_AUTOMATED_RESPONSE = gql`
        mutation cancelAutomatedResponse($crmId: ID!) {
            cancelAutomatedResponse(
                crmId: $crmId
            )
        }
    `;
}
