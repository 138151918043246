import { gql } from '@apollo/client';

export default class CreditQuery {
    static GET_HISTORY_CREDITS = gql`
        query getHistoryCredits($recordId: Int!, $recordType: String!) {
            getHistoryCredits(recordId: $recordId, recordType: $recordType) {
                creditPullHistories {
                    type
                    bureaus
                    transactionId
                    reportType
                    createdOn
                    creditHistoryScoreId
                }
                prospectHistory {
                    createdOn
                    TRANSUNION
                    EXPERIAN
                    EQUIFAX
                    creditHistoryScoreId
                }
                coProspectHistory {
                    createdOn
                    TRANSUNION
                    EXPERIAN
                    EQUIFAX
                    creditHistoryScoreId
                }
            }
        }
    `;

    static GET_CREDIT_REPORT_URL= gql`
        query getCreditReportURL($transactionId: String!) {
            getCreditReportURL(transactionId: $transactionId) {
                url
                vendor
            }
        }
    `;

    static LIST_DEALER_INTEGRATIONS = gql`
        query listDealerIntegrations($searchText: String, $active: Boolean) {
            listDealerIntegrations(searchText: $searchText, active: $active) {
                bureaus {
                    bureauCompanyId
                    name
                    defaultCheck
                    active
                }
                lotId
                lotName
            }
        }
    `;
}
