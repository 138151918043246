import React, { Component } from 'react';

// Components ant Others
import PropTypes from 'prop-types';
import { find, filter } from 'lodash';
import KeyStore from 'utils/KeyStore';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import { FormsActions } from 'utils/enum/DealEnum';

// GraphQL
import FormService from 'services/modules/FormService';

const CreateFormPacksContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        hiddenFormPack: PropTypes.func.isRequired,
        records: PropTypes.array.isRequired,
        action: PropTypes.string,
        record: PropTypes.object,
    }

    static defaultProps = {
        action: '',
        record: {},
    }

    constructor(props) {
        super(props);
        this.formService = new FormService();
        this.keyStore = new KeyStore();

        this.state = {
            packName: props.record?.packName || '',
        };

        this.initBind();
    }

    handleKeyDown(e) {
        const { hiddenFormPack } = this.props;
        const { packName } = this.state;

        if (e.keyCode === 27) {
            hiddenFormPack();
        }

        if (e.keyCode === 13 && !StringUtils.isEmpty(packName) && !this.isDuplicatePackName(packName)) {
            this.handleClick();
        }
    }

    handleClick() {
        const { action } = this.props;

        switch (action) {
        case FormsActions.RENAME.toUpperCase():
            this.renameFormPack();
            break;
        case FormsActions.DUPLICATE.toUpperCase():
            this.duplicateFormPack();
            break;
        default:
            this.saveFormPack();
        }
    }

    onChangeValue(e) {
        const packName = e.target.value;

        this.setState({
            packName,
        });
    }

    isDuplicatePackName(packName) {
        const { records, action, record } = this.props;

        if (action === FormsActions.RENAME.toUpperCase()) {
            const currentRecord = filter(records, (item) => item.packId !== record?.packId);
            return !!find(currentRecord, (item) => item?.packName?.toUpperCase() === packName?.toUpperCase());
        }

        return !!find(records, (item) => item?.packName?.toUpperCase() === packName?.toUpperCase());
    }

    initBind() {
        this.handleClick = this.handleClick.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
        this.renameFormPack = this.renameFormPack.bind(this);
        this.isDuplicatePackName = this.isDuplicatePackName.bind(this);
    }

    renameFormPack() {
        const { hiddenFormPack, record } = this.props;
        const { packName } = this.state;
        const currenPackName = packName?.trim();

        const input = {
            packName: currenPackName,
            lotId: record.lotId,
            packId: record.packId,
        };

        this.formService.renameFormPack(input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.renameFormPack) {
                    hiddenFormPack();
                }
            });
    }

    duplicateFormPack() {
        const { hiddenFormPack, record } = this.props;
        const { packName } = this.state;
        const currenPackName = packName?.trim();

        const input = {
            newName: currenPackName,
            oldPackId: record.packId,
        };

        this.formService.duplicateFormPack(input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.duplicateFormPack) {
                    hiddenFormPack();
                }
            });
    }

    saveFormPack() {
        const { hiddenFormPack } = this.props;
        const { packName } = this.state;
        const selectedLot = this.keyStore.getSelectedLot();
        const currenPackName = packName?.trim();

        const input = {
            packName: currenPackName,
            lotId: selectedLot.lotId,
        };

        this.formService.createFormPack({ input })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.createFormPack) {
                    hiddenFormPack();
                }
            });
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                handleClick={this.handleClick}
                handleKeyDown={this.handleKeyDown}
                onChangeValue={this.onChangeValue}
                isDuplicatePackName={this.isDuplicatePackName}
            />
        );
    }
};

export default CreateFormPacksContainer;
