import React, { useEffect, useReducer } from 'react';

// Material UI
import {
    Grid, makeStyles, Checkbox, FormControlLabel, FormGroup,
    Button,
} from '@material-ui/core';

// Components and Others
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import { Col, Form } from 'react-bootstrap';
import { FetchPolicy } from 'utils/enum/Core';
import Select from 'components/widgets/Select';
import ButtonStyles from 'styles/theme/Button';
import Loading from 'components/widgets/Loading';
import { useMutation, useQuery } from '@apollo/client';
import CreditQuery from 'services/graphQL/query/credit/CreditQuery';
import CreditHelper from 'components/modules/crm/read/credit/CreditHelper';
import CreditMutation from 'services/graphQL/mutate/credit/CreditMutation';
import CreditHistory from 'components/modules/crm/read/credit/CreditHistory';
import CreditReportDialog from 'components/modules/crm/read/credit/CreditReportDialog';
import CreditScoreCardHistory from 'components/modules/crm/read/credit/CreditScoreCardHistory';
import StringUtils from 'lib/StringUtils';

const reportType = {
    JOINT: 'Joint',
    INDIVIDUAL: 'Individual',
};
const modules = {
    CRM: 'CRM',
    DEAL: 'DEAL',
};
const reportTypeOptions = Object.keys(reportType).map((item) => ({ label: reportType[item], value: reportType[item] }));
const useStyles = makeStyles((theme) => ({
    creditOption: {
        background: theme.palette.background.white,
        marginRight: theme.spacing(2),
        padding: theme.spacing(2),
        minWidth: 250,
        width: 250,
    },
    boxContainer: {
        background: theme.palette.background.default,
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        width: '100%',
        padding: theme.spacing(2),
    },
    container: {
        display: 'flex',
        overflow: 'auto',
        flexWrap: 'initial',
    },
    containerConfiguration: {
        width: '100%',
        height: '100%',
        background: theme.palette.background.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    ...ButtonStyles.getStyle(theme),
}));
const ACTION_TYPE = {
    ON_CLOSE: 'onClose',
    ON_CHANGE_VALUE: 'onChangeValue',
    ON_SELECT_RECORD: 'onSelectRecord',
    SET_CREDIT_SCORE: 'setCreditScore',
    ON_CHANGE_BUREAUS: 'onChangeBureaus',
    SET_HISTORY_CREDITS: 'setHistoryCredits',
    SET_DEALER_INTEGRATIONS: 'setDealerIntegrations',
};
const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPE.SET_HISTORY_CREDITS:
        return update(state, {
            prospectHistory: { $set: action.payload.prospectHistory },
            coProspectHistory: { $set: action.payload.coProspectHistory },
            creditPullHistories: { $set: action.payload.creditPullHistories },
        });
    case ACTION_TYPE.ON_SELECT_RECORD:
        return update(state, {
            creditPullHistorySelected: { $set: action.payload },
        });
    case ACTION_TYPE.SET_DEALER_INTEGRATIONS:
        let bureaus = [];

        if (action.recordType === modules.CRM) {
            bureaus = action.payload?.find((item) => item.lotId === action.lotId)?.bureaus;
        } else if (action.recordType === modules.DEAL) {
            bureaus = action.payload?.find((item) => item.lotName === action.lotName)?.bureaus;
        }

        return update(state, {
            dealerIntegrations: { $set: action.payload },
            bureausByLot: { $set: (bureaus || []).filter((item) => item.active).map((item) => ({ label: item.name, value: item.defaultCheck })) },
        });
    case ACTION_TYPE.ON_CHANGE_BUREAUS:
        return update(state, {
            bureausByLot: { [action.payload.index]: { value: { $set: action.payload.value } } },
        });
    case ACTION_TYPE.ON_CHANGE_VALUE:
        return update(state, {
            [action.payload.field]: { $set: action.payload.value },
        });
    case ACTION_TYPE.SET_CREDIT_SCORE:
        return update(state, {
            creditScoreURL: { $set: action.payload?.url },
            open: { $set: true },
        });
    case ACTION_TYPE.ON_CLOSE:
        return update(state, {
            open: { $set: false },
        });
    default:
        return state;
    }
};
const initState = {
    open: false,
    bureausByLot: [],
    reportType: null,
    creditScoreURL: null,
    prospectHistory: [],
    coProspectHistory: [],
    dealerIntegrations: [],
    creditPullHistories: [],
    creditPullHistorySelected: null,
};
const CreditDetail = ({
    recordId,
    recordType,
    lotId,
    lotName,
}) => {
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initState);
    const {
        data, error, loading,
    } = useQuery(CreditQuery.GET_HISTORY_CREDITS, { variables: { recordId, recordType }, skip: recordId === null, fetchPolicy: FetchPolicy.NETWORK_ONLY });
    const {
        data: dealerIntegrations, error: errorDealerIntegrations, loading: loadingDealerIntegrations,
    } = useQuery(CreditQuery.LIST_DEALER_INTEGRATIONS, { variables: { active: true }, fetchPolicy: FetchPolicy.NETWORK_ONLY });
    const [generateCreditScore, { loading: loadingCreditScore }] = useMutation(CreditMutation.GENERATE_CREDIT_SCORE, {
        onCompleted: (response) => {
            if (!StringUtils.isEmpty(response?.generateCreditScore?.url)) {
                dispatch({ type: ACTION_TYPE.SET_CREDIT_SCORE, payload: response.generateCreditScore });
            }
        },
        onError: (errorMessage) => {
            const { message, graphQLErrors } = errorMessage;
            ModalUtils.errorMessage((graphQLErrors?.length > 0 ? graphQLErrors : null), message, {
                title: 'Message',
                description: 'The following information is required to pull the credit report:',
            });
        },
    });

    const onSelectedRecord = (record) => {
        dispatch({
            type: ACTION_TYPE.ON_SELECT_RECORD,
            payload: record,
        });
    };

    const onChangeBureaus = (value, index) => {
        dispatch({
            type: ACTION_TYPE.ON_CHANGE_BUREAUS,
            payload: {
                value,
                index,
            },
        });
    };

    const onChangeValue = (field, value) => {
        dispatch({
            type: ACTION_TYPE.ON_CHANGE_VALUE,
            payload: {
                field,
                value,
            },
        });
    };

    const onClose = () => {
        dispatch({ type: ACTION_TYPE.ON_CLOSE });
    };

    const onRequestReport = () => {
        const multipleBureaus = CreditHelper.getCodeMultiBureaus(state.bureausByLot.filter((item) => item.value).map((item) => item.label));
        generateCreditScore({
            variables: {
                recordId,
                recordType,
                type: state.reportType,
                multipleBureaus,
            },
        });
    };

    useEffect(() => {
        // TODO: Show message error when repairing getHistoryCredits query.
        if (error) {
            return;
        }

        if (!loading && !isEmpty(data?.getHistoryCredits)) {
            const { getHistoryCredits } = data;
            dispatch({ type: ACTION_TYPE.SET_HISTORY_CREDITS, payload: getHistoryCredits });
        }
    }, [data, error, loading]);

    useEffect(() => {
        if (errorDealerIntegrations) {
            ModalUtils.errorMessage(errorDealerIntegrations?.graphQLErrors);
            return;
        }

        if (!loadingDealerIntegrations && !isEmpty(dealerIntegrations?.listDealerIntegrations)) {
            const { listDealerIntegrations } = dealerIntegrations;
            dispatch({
                type: ACTION_TYPE.SET_DEALER_INTEGRATIONS,
                payload: listDealerIntegrations,
                lotId,
                lotName,
                recordType,
            });
        }
    }, [dealerIntegrations, errorDealerIntegrations, loadingDealerIntegrations, lotId, lotName, recordType]);

    if (loadingDealerIntegrations) {
        return <Loading />;
    }

    if (isEmpty(state.bureausByLot)) {
        const moduleName = recordType === modules.CRM ? 'opportunity' : 'deal';
        const description = `Credit score is not available for the ${moduleName} lot.`;

        return (
            <div className={classes.boxContainer}>
                <div className={classes.containerConfiguration}>{description}</div>
            </div>
        );
    }

    return (
        <div className={classes.boxContainer}>
            <div className={clsx('box-shadow', classes.creditOption)}>
                <Form.Group as={Col} className="p-0">
                    <Form.Label className={classes.labels}>Report Type</Form.Label>
                    <Select
                        size="sm"
                        name="reportType"
                        value={state.reportType}
                        onChange={onChangeValue}
                        options={reportTypeOptions}
                    />
                </Form.Group>
                <Form.Group as={Col} className="p-0">
                    <Form.Label className={classes.labels}>Bureaus Requested</Form.Label>
                    <FormGroup>
                        {state.bureausByLot.map((item, index) => (
                            <FormControlLabel
                                key={index}
                                control={<Checkbox name={item.label} checked={item.value} onChange={(e) => onChangeBureaus(e.target.checked, index)} />}
                                label={item.label}
                            />
                        ))}
                    </FormGroup>
                </Form.Group>
                <Button
                    fullWidth
                    size="small"
                    variant="contained"
                    onClick={onRequestReport}
                    className={classes.containedSecondaryInfo}
                    disabled={state.reportType === null || !state.bureausByLot.some((item) => item.value) || loadingCreditScore}
                >
                    Request Report

                </Button>
            </div>
            <Grid container spacing={2} className={classes.container} alignContent="flex-start">
                <Grid item xs={6}>
                    <CreditHistory
                        records={state.creditPullHistories}
                        onSelectedRecord={onSelectedRecord}
                        recordSelected={state.creditPullHistorySelected}
                    />
                </Grid>
                <Grid item xs={6} className={classes.column}>
                    <Grid container spacing={2} className={classes.personalInformation}>
                        <Grid item xs={12}>
                            <CreditScoreCardHistory
                                records={state.prospectHistory}
                                title="Credit Score History - Prospect"
                                recordSelected={state.creditPullHistorySelected}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CreditScoreCardHistory
                                records={state.coProspectHistory}
                                title="Credit Score History - Co-Prospect"
                                recordSelected={state.creditPullHistorySelected}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {state.open && <CreditReportDialog url={state.creditScoreURL} open={state.open} onClose={onClose} />}
        </div>
    );
};

CreditDetail.propTypes = {
    lotId: PropTypes.number,
    lotName: PropTypes.string,
    recordId: PropTypes.number,
    recordType: PropTypes.string.isRequired,
};

CreditDetail.defaultProps = {
    recordId: null,
    lotId: null,
    lotName: null,
};

export default CreditDetail;
